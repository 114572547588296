/* eslint-disable no-param-reassign */
import { BoxTypeFragment, OrderFragment, PackTypeFragment, useAddPackMutation } from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import { FormControl, InputAdornment, InputLabel, SelectChangeEvent } from "@mui/material"
import { Alert, Box, Button, CardContent, Grid, Stack, TextField, Typography } from "@northvolt/ui"
import {
  CancelButton,
  CardActions,
  DropdownMenuPack,
  IconPlus,
  NumberSelect,
  RenderError,
} from "@shared"
import { JSX } from "react"
import { DefaultValues, FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Pack } from "../Pack/Pack"

export const packSchema = z
  .object({
    packId: z.string(),
    packTypeId: z.string(),
    displayName: z.string(),
    netWeight: z.number().min(0),
    green: z.coerce.number().min(0),
    yellow: z.coerce.number().min(0),
    red: z.coerce.number().min(0),
  })
  .refine((m) => m.green + m.yellow + m.red > 0, {
    message: "You must have at least one pack of any status",
  })
export type ZodPack = z.infer<typeof packSchema>
const boxSchema = z.object({
  packs: z.array(packSchema).min(1),
  copies: z.coerce.number().positive(),
})
export type ZodInputTypes = z.infer<typeof boxSchema>

type AddPackProps = {
  order: OrderFragment
  packTypes: PackTypeFragment[]
  selectedBoxType: BoxTypeFragment
  setAdding: (state: boolean) => void
}

export const AddPack = ({
  order,
  packTypes,
  selectedBoxType,
  setAdding,
}: AddPackProps): JSX.Element => {
  const { t } = useTranslation()
  const [addPack] = useAddPackMutation()
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    packs: [],
    copies: 1,
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(boxSchema),
    // Using onChange has a larger performance impact than onBlur, but the radio buttons don't
    // seem to handle onBlur properly, causing the add button to be disabled until a different
    // field is blurred. This is confusing to the user, so instead we stick with onChange.
    mode: "onChange",
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid },
  } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "packs",
  })
  const watchPacks = watch("packs")

  const netWeight = watchPacks.reduce(
    (acc, m) => acc + m.netWeight * (m.green + m.yellow + m.red),
    0,
  )
  const grossWeight = netWeight + selectedBoxType.netWeight
  const maxPacks = 4 // Define the max number of packs allowed

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    fields.forEach((field, index) => {
      if (!value.includes(field.packTypeId)) {
        remove(index)
      }
    })
    if (typeof value === "object") {
      value.forEach((newId) => {
        const existingIndex = fields.findIndex((m) => m.packTypeId === newId)
        if (existingIndex === -1) {
          const packType = packTypes.find((m) => m.id === newId)
          if (packType !== undefined) {
            append({
              packId: newId,
              packTypeId: packType.id,
              displayName: packType.displayName ?? "",
              netWeight: packType.defaultNetWeight,
              green: 0,
              yellow: 0,
              red: 0,
            })
          }
        }
      })
    }
  }

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes) => {
    addPack({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          boxType: {
            internalID: selectedBoxType.id,
            displayName: selectedBoxType.displayName,
          },
          count: data.copies,
          packs: data.packs.map((m) => ({
            fileIDs: [],
            packType: {
              internalID: m.packTypeId,
              displayName: m.displayName,
            },
            netWeight: m.netWeight,
            green: m.green,
            yellow: m.yellow,
            red: m.red,
          })),
        },
      },
    }).then(() => {
      setAdding(false)
    })
  }

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack gap={4}>
            <Alert severity="info">
              {t("components.AddPack.packLimitDescription", { limit: maxPacks.toString() })}
            </Alert>
            <FormControl>
              <InputLabel>{t("components.basics.selectPack.label")}</InputLabel>
              <DropdownMenuPack
                fields={fields}
                packTypes={packTypes}
                handleChange={handleChange}
                maxPacks={maxPacks}
              />
            </FormControl>
            {fields.map((pack, index) => (
              <Pack key={pack.id} index={index} pack={pack} remove={remove} />
            ))}
            {watchPacks.length > 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid sm={12} md={6}>
                    <TextField
                      disabled
                      label={t("components.basics.netWeight")}
                      fullWidth
                      value={netWeight}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid sm={12} md={6}>
                    <TextField
                      disabled
                      label={t("components.basics.grossWeight")}
                      fullWidth
                      value={grossWeight}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ boxRadius: "4px", padding: "16px" }} bgcolor="background.default">
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontSize={16} component="h2" sx={{ margin: 2 }}>
                      {t("components.AddPack.copies.title")}
                    </Typography>
                    <NumberSelect
                      control={control}
                      setValue={setValue}
                      name="copies"
                      minNumber={1}
                    />
                  </Stack>
                  <RenderError control={control} name="copies" />
                </Box>
              </>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => setAdding(false)} />
          <Button
            type="submit"
            color="secondary"
            data-testid="add-box-submit"
            disabled={!isValid}
            startIcon={<IconPlus />}
          >
            {t("components.AddBox.submit")}
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  )
}
