import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import { OrderFragment, OrderStatus, useExistingPickupOrderFlowQuery } from "@gen/graphql"
import { IconButton, Stack, Typography } from "@mui/material"
import { IconArrowLeft, Loader, RouteError } from "@shared"
import { Outlet, createFileRoute, useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PickupOrderBaseProps = {
  order: OrderFragment
  submittedOrderId: string
}

const PickupOrderBase = ({ order, submittedOrderId }: PickupOrderBaseProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const title =
    order.status === OrderStatus.Draft ? t("routes.pickupOrder") : t("routes.index.followUp.backTo")

  return (
    <>
      {order.status === OrderStatus.Draft ? (
        <Typography variant="headlineMedium" mb={2}>
          {title}
        </Typography>
      ) : (
        <Stack direction="row" mb={2} alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              navigate({
                to: "/overview/$orderId",
                params: {
                  orderId: order.id,
                },
              })
            }}
          >
            <IconArrowLeft />
          </IconButton>
          <Typography variant="headlineMedium">{title}</Typography>
        </Stack>
      )}
      {submittedOrderId && <SubmittedModal order={order} />}
      <Outlet />
    </>
  )
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { submittedOrderId } = Route.useSearch()
  const { orderId } = Route.useParams()
  const { data, error, loading } = useExistingPickupOrderFlowQuery({
    variables: {
      id: orderId,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupOrderBase order={data.pickupOrder} submittedOrderId={submittedOrderId} />
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId")({
  component: RouteComponent,
  validateSearch: (
    search,
  ): {
    submittedOrderId: string
  } => {
    return {
      submittedOrderId: search.submittedOrderId as string,
    }
  },
})
