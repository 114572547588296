import {
  OrderFragment,
  OrderStatus,
  SubscriberFragment,
  useAddContactMutation,
  useAddSubscriberMutation,
  useDeleteContactMutation,
  useDeleteSubscriberMutation,
} from "@gen/graphql"
import CloseIcon from "@mui/icons-material/Close"
import { Grid, IconButton } from "@mui/material"
import { Box } from "@mui/system"
import { Button, Stack, TextField, Typography, useSnackbar } from "@northvolt/ui"
import { ConfirmationDialog, ConfirmationDialogWithTextfield, IconPlus, IconShare } from "@shared"
import { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

type ContactInputProps = {
  order: OrderFragment
  onHideInput: () => void
}

const ContactInput = ({ order, onHideInput }: ContactInputProps): JSX.Element => {
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [addContact] = useAddContactMutation()
  const updateSnackbar = useSnackbar()

  const onSubmit = async (): Promise<void> => {
    try {
      await addContact({
        variables: {
          input: {
            contactName: name,
            phone: phone,
            pickupOrderId: order.id,
          },
        },
      }).then(onHideInput)
    } catch (error) {
      updateSnackbar({
        message: `Error adding contact: ${error}`,
        severity: "error",
      })
    }
  }

  return (
    <Box sx={{ my: 2 }}>
      <Typography>{t("components.Contacts.title")}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("components.Contacts.labelName")}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("components.Contacts.labelPhone")}
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mr: 1 }}>
        <Button variant="text" color="secondary" onClick={onHideInput} sx={{ mr: 1 }}>
          {t("components.Contacts.cancel")}
        </Button>
        <Button color="secondary" onClick={onSubmit}>
          {t("components.Contacts.add")}
        </Button>
      </Box>
    </Box>
  )
}

type ContactProps = {
  order: OrderFragment
}
export const Contacts = ({ order }: ContactProps): JSX.Element => {
  const { t } = useTranslation()
  const [showInput, setShowInput] = useState(false)
  const updateSnackbar = useSnackbar()

  const [deleteContact] = useDeleteContactMutation()
  const handleRemove = async (contactID: string): Promise<void> => {
    try {
      await deleteContact({
        variables: {
          input: {
            pickupOrderId: order.id,
            contactId: contactID,
          },
        },
      })
    } catch (error) {
      updateSnackbar({
        message: `Error removing contact: ${error}`,
        severity: "error",
      })
    }
  }

  const toggleInput = (): void => {
    setShowInput((prev) => !prev)
  }

  return (
    <Box mt={3}>
      {order.contacts.length > 0 &&
        order.contacts.map((contact, index) => (
          <Box key={contact.id} sx={{ mt: 1, bgcolor: "background.default", p: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>
                {t("components.Contacts.contactPerson")} {index + 1}: {contact.contactName} -{" "}
                {contact.phone}
              </Typography>
              <IconButton
                onClick={() => handleRemove(contact.id)}
                data-testid={`remove-contact-${index}`}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
        ))}

      {showInput ? (
        <ContactInput order={order} onHideInput={toggleInput} />
      ) : (
        <Button
          variant="text"
          color="secondary"
          startIcon={<IconPlus />}
          onClick={toggleInput}
          sx={{ mt: 2 }}
          data-testid="add-pickup-contact-info"
        >
          {t("components.Contacts.addedContact")}
        </Button>
      )}
    </Box>
  )
}

export const SubscriberInput = ({ order }: ContactProps): JSX.Element => {
  const [openDialog, setOpenDialog] = useState(false)
  const [addSubscriber] = useAddSubscriberMutation()
  const updateSnackbar = useSnackbar()
  const { t } = useTranslation()

  const onConfirm = async (textFieldValue: string, orderID: string): Promise<void> => {
    try {
      await addSubscriber({
        variables: {
          input: {
            email: textFieldValue,
            pickupOrderId: orderID,
          },
        },
      }).then(() => setOpenDialog(false))
    } catch (error) {
      updateSnackbar({
        message: `Error adding subscriber: ${error}`,
        severity: "error",
      })
    }
  }

  const disableEdit = order.status === OrderStatus.AwaitingApproval

  return (
    <>
      <Button
        size="small"
        variant="text"
        color="secondary"
        startIcon={<IconShare />}
        sx={{ p: 1 }}
        onClick={() => setOpenDialog(true)}
        disabled={disableEdit}
      >
        {t("components.Contacts.addSubscriber")}
      </Button>
      <ConfirmationDialogWithTextfield
        orderID={order?.id}
        open={openDialog}
        title={t("components.ConfirmationDialog.title.addSubscriber")}
        message={t("components.ConfirmationDialog.message.addSubscriber")}
        textFieldLabel={t("components.ConfirmationDialog.textFieldLabel")}
        confirmButtonText={t("components.ConfirmationDialog.add")}
        cancelButtonText={t("components.ConfirmationDialog.cancel")}
        onConfirm={onConfirm}
        onCancel={() => setOpenDialog(false)}
      />
    </>
  )
}

export const Subscribers = ({ order }: ContactProps): JSX.Element => {
  const { t } = useTranslation()
  const disableEdit = order.status === OrderStatus.AwaitingApproval

  return (
    <>
      {order.subscribers.length > 0 && (
        <Stack spacing={1} mb={1}>
          <Typography variant="bodySmall" color="secondary">
            {t("components.Contacts.subscribers")}
          </Typography>
          {order.subscribers.map((subscriber, index) => (
            <Subscriber
              key={subscriber.id}
              subscriber={subscriber}
              index={index}
              orderID={order.id}
              disable={disableEdit}
            />
          ))}
        </Stack>
      )}
    </>
  )
}

type SubscriberProps = {
  subscriber: SubscriberFragment
  index: number
  orderID: string
  disable: boolean
}

const Subscriber = ({
  subscriber,
  index,
  orderID,
  disable = false,
}: SubscriberProps): JSX.Element => {
  const [deleteSubscriber] = useDeleteSubscriberMutation()
  const [openDialog, setOpenDialog] = useState(false)
  const updateSnackbar = useSnackbar()
  const { t } = useTranslation()

  const handleRemove = async (contactID: string): Promise<void> => {
    try {
      await deleteSubscriber({
        variables: {
          input: {
            pickupOrderId: orderID,
            contactId: contactID,
          },
        },
      }).then(() => setOpenDialog(false))
    } catch (error) {
      updateSnackbar({
        message: `Error removing subscriber: ${error}`,
        severity: "error",
      })
    }
  }

  return (
    <Stack
      key={subscriber.id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="background.default"
      pl={1}
    >
      <Typography>{subscriber.email}</Typography>
      <IconButton
        onClick={() => setOpenDialog(true)}
        data-testid={`remove-subscriber-${index}`}
        disabled={disable}
      >
        <CloseIcon />
      </IconButton>
      <ConfirmationDialog
        open={openDialog}
        title={t("components.ConfirmationDialog.title.deleteSubscriber")}
        cancelButtonText={t("components.ConfirmationDialog.cancel")}
        confirmButtonText={t("components.ConfirmationDialog.remove")}
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => handleRemove(subscriber.id)}
      ></ConfirmationDialog>
    </Stack>
  )
}
