import { OrderFragment, OrderStatus } from "@gen/graphql"
import { Button, CardHeader } from "@mui/material"
import { Box, Card, CardContent, IconButton, Stack, Typography, useSnackbar } from "@northvolt/ui"
import { IconPen, disableDates, getEarliestPickupDate, sumOrderGrossWeight } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import dayjs from "dayjs"
import { JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SubscriberInput, Subscribers } from "../Contacts/Contacts"
import { ListOrderBoxes } from "../ListOrderBoxes/ListOrderBoxes"

type OrderSummaryProps = {
  stepId: string
  order: OrderFragment | null
  submit?: () => void
}

export const OrderSummary = ({ stepId, order, submit }: OrderSummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()

  const haveBoxes = (order?.boxes.length ?? 0) > 0
  const grossWeight = useMemo(() => {
    return order ? sumOrderGrossWeight(order) : 0
  }, [order])

  const earliestPickupDate = getEarliestPickupDate()
  const tooEarlyPickupDate =
    !!order?.pickupDate && disableDates(earliestPickupDate)(dayjs(order.pickupDate))
  const wrongStatus = order?.status !== OrderStatus.Draft && order?.status !== OrderStatus.Pending
  const disableButton = wrongStatus || tooEarlyPickupDate

  const handleClick = (): void => {
    if (tooEarlyPickupDate) {
      updateSnackbar({
        message: t("components.OrderSummary.tooEarlyPickupDate"),
        severity: "error",
      })
    }
    if (wrongStatus) {
      updateSnackbar({
        message: t("components.OrderSummary.wrongStatus"),
        severity: "error",
      })
    }
  }

  const disableEdit = order?.status === OrderStatus.AwaitingApproval

  const isSubmittable = submit && haveBoxes && order?.status === OrderStatus.Draft

  // TODO: Here we need to do the same with modules as well
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "headlineSmall" }}
        title={t("components.OrderSummary.title")}
        action={order ? <SubscriberInput order={order} /> : null}
      />
      <CardContent>
        {order && <Subscribers order={order} />}
        <Stack spacing={1}>
          {order && (
            <Box
              sx={{
                mt: 1,
                border: 1,
                borderColor: "grey.700",
                borderRadius: 1,
                padding: 2,
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box flexGrow={1}>
                  <Typography>
                    {t("components.OrderSummary.pickupDate", {
                      date: order.pickupDate,
                    })}
                  </Typography>
                  <Typography>
                    {t("components.OrderSummary.pickupLocation", {
                      location: order.pickupLocation?.displayName,
                    })}
                  </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigate({
                        to: "/pickup-orders/$orderId/$stepId/edit",
                        params: {
                          orderId: order.id,
                          stepId: "1",
                        },
                      })
                    }}
                    disabled={disableEdit}
                  >
                    <IconPen />
                  </IconButton>
                </Box>
              </Box>
              {order.contacts.length > 0 && (
                <Box sx={{ my: 1 }}>
                  <Typography variant="bodySmall" color="secondary">
                    {t("components.Contacts.addedContact")}:
                  </Typography>
                  {order.contacts.map((contact, index) => (
                    <Box key={contact.id}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography>
                          {t("components.Contacts.contactPerson")} {index + 1}:{" "}
                          {contact.contactName} - {contact.phone}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
          {!haveBoxes && <Typography>{t("components.OrderSummary.emptyOrder")}</Typography>}
          {haveBoxes && (
            <>
              <Typography fontSize={16} component="h2" sx={{ mt: 2 }}>
                <Stack direction="row" gap={5}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>{t("components.OrderSummary.totalPackages")}</Typography>
                    <Typography variant="headlineSmall">{order?.boxes.length}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>{t("components.OrderSummary.grossWeight")}</Typography>
                    <Typography variant="headlineSmall">{grossWeight} kg</Typography>
                  </Stack>
                </Stack>
              </Typography>
              {order && (
                <ListOrderBoxes
                  order={order}
                  stepId={stepId}
                  editable={
                    order.status === OrderStatus.Draft || order.status === OrderStatus.Pending
                  }
                />
              )}
            </>
          )}
          {isSubmittable && (
            <>
              <div onClick={handleClick}>
                <Button fullWidth sx={{ mt: 5 }} onClick={submit} disabled={disableButton}>
                  <>
                    {order?.status === OrderStatus.Draft && t("components.OrderSummary.submit")}
                    {order?.status !== OrderStatus.Draft && t("components.OrderSummary.resubmit")}
                  </>
                </Button>
              </div>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  navigate({
                    to: "/overview",
                  })
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
