/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./../routes/__root"
import { Route as ProfileImport } from "./../routes/profile"
import { Route as PickupOrdersImport } from "./../routes/pickup-orders"
import { Route as OverviewImport } from "./../routes/overview"
import { Route as IndexImport } from "./../routes/index"
import { Route as PickupOrdersIndexImport } from "./../routes/pickup-orders.index"
import { Route as OverviewOrderIdImport } from "./../routes/overview.$orderId"
import { Route as PickupOrdersOrderIdStepIdImport } from "./../routes/pickup-orders.$orderId.$stepId"
import { Route as PickupOrdersOrderIdStepIdIndexImport } from "./../routes/pickup-orders.$orderId.$stepId.index"
import { Route as PickupOrdersOrderIdStepIdEditImport } from "./../routes/pickup-orders.$orderId.$stepId.edit"
import { Route as PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditImport } from "./../routes/pickup-orders.$orderId.$stepId.groups.$groupId.packages_.$packageId.edit"

// Create/Update Routes

const ProfileRoute = ProfileImport.update({
  id: "/profile",
  path: "/profile",
  getParentRoute: () => rootRoute,
} as any)

const PickupOrdersRoute = PickupOrdersImport.update({
  id: "/pickup-orders",
  path: "/pickup-orders",
  getParentRoute: () => rootRoute,
} as any)

const OverviewRoute = OverviewImport.update({
  id: "/overview",
  path: "/overview",
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any)

const PickupOrdersIndexRoute = PickupOrdersIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => PickupOrdersRoute,
} as any)

const OverviewOrderIdRoute = OverviewOrderIdImport.update({
  id: "/$orderId",
  path: "/$orderId",
  getParentRoute: () => OverviewRoute,
} as any)

const PickupOrdersOrderIdStepIdRoute = PickupOrdersOrderIdStepIdImport.update({
  id: "/$orderId/$stepId",
  path: "/$orderId/$stepId",
  getParentRoute: () => PickupOrdersRoute,
} as any)

const PickupOrdersOrderIdStepIdIndexRoute =
  PickupOrdersOrderIdStepIdIndexImport.update({
    id: "/",
    path: "/",
    getParentRoute: () => PickupOrdersOrderIdStepIdRoute,
  } as any)

const PickupOrdersOrderIdStepIdEditRoute =
  PickupOrdersOrderIdStepIdEditImport.update({
    id: "/edit",
    path: "/edit",
    getParentRoute: () => PickupOrdersOrderIdStepIdRoute,
  } as any)

const PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute =
  PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditImport.update({
    id: "/groups/$groupId/packages_/$packageId/edit",
    path: "/groups/$groupId/packages/$packageId/edit",
    getParentRoute: () => PickupOrdersOrderIdStepIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    "/overview": {
      id: "/overview"
      path: "/overview"
      fullPath: "/overview"
      preLoaderRoute: typeof OverviewImport
      parentRoute: typeof rootRoute
    }
    "/pickup-orders": {
      id: "/pickup-orders"
      path: "/pickup-orders"
      fullPath: "/pickup-orders"
      preLoaderRoute: typeof PickupOrdersImport
      parentRoute: typeof rootRoute
    }
    "/profile": {
      id: "/profile"
      path: "/profile"
      fullPath: "/profile"
      preLoaderRoute: typeof ProfileImport
      parentRoute: typeof rootRoute
    }
    "/overview/$orderId": {
      id: "/overview/$orderId"
      path: "/$orderId"
      fullPath: "/overview/$orderId"
      preLoaderRoute: typeof OverviewOrderIdImport
      parentRoute: typeof OverviewImport
    }
    "/pickup-orders/": {
      id: "/pickup-orders/"
      path: "/"
      fullPath: "/pickup-orders/"
      preLoaderRoute: typeof PickupOrdersIndexImport
      parentRoute: typeof PickupOrdersImport
    }
    "/pickup-orders/$orderId/$stepId": {
      id: "/pickup-orders/$orderId/$stepId"
      path: "/$orderId/$stepId"
      fullPath: "/pickup-orders/$orderId/$stepId"
      preLoaderRoute: typeof PickupOrdersOrderIdStepIdImport
      parentRoute: typeof PickupOrdersImport
    }
    "/pickup-orders/$orderId/$stepId/edit": {
      id: "/pickup-orders/$orderId/$stepId/edit"
      path: "/edit"
      fullPath: "/pickup-orders/$orderId/$stepId/edit"
      preLoaderRoute: typeof PickupOrdersOrderIdStepIdEditImport
      parentRoute: typeof PickupOrdersOrderIdStepIdImport
    }
    "/pickup-orders/$orderId/$stepId/": {
      id: "/pickup-orders/$orderId/$stepId/"
      path: "/"
      fullPath: "/pickup-orders/$orderId/$stepId/"
      preLoaderRoute: typeof PickupOrdersOrderIdStepIdIndexImport
      parentRoute: typeof PickupOrdersOrderIdStepIdImport
    }
    "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit": {
      id: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit"
      path: "/groups/$groupId/packages/$packageId/edit"
      fullPath: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit"
      preLoaderRoute: typeof PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditImport
      parentRoute: typeof PickupOrdersOrderIdStepIdImport
    }
  }
}

// Create and export the route tree

interface OverviewRouteChildren {
  OverviewOrderIdRoute: typeof OverviewOrderIdRoute
}

const OverviewRouteChildren: OverviewRouteChildren = {
  OverviewOrderIdRoute: OverviewOrderIdRoute,
}

const OverviewRouteWithChildren = OverviewRoute._addFileChildren(
  OverviewRouteChildren,
)

interface PickupOrdersOrderIdStepIdRouteChildren {
  PickupOrdersOrderIdStepIdEditRoute: typeof PickupOrdersOrderIdStepIdEditRoute
  PickupOrdersOrderIdStepIdIndexRoute: typeof PickupOrdersOrderIdStepIdIndexRoute
  PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute: typeof PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute
}

const PickupOrdersOrderIdStepIdRouteChildren: PickupOrdersOrderIdStepIdRouteChildren =
  {
    PickupOrdersOrderIdStepIdEditRoute: PickupOrdersOrderIdStepIdEditRoute,
    PickupOrdersOrderIdStepIdIndexRoute: PickupOrdersOrderIdStepIdIndexRoute,
    PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute:
      PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute,
  }

const PickupOrdersOrderIdStepIdRouteWithChildren =
  PickupOrdersOrderIdStepIdRoute._addFileChildren(
    PickupOrdersOrderIdStepIdRouteChildren,
  )

interface PickupOrdersRouteChildren {
  PickupOrdersIndexRoute: typeof PickupOrdersIndexRoute
  PickupOrdersOrderIdStepIdRoute: typeof PickupOrdersOrderIdStepIdRouteWithChildren
}

const PickupOrdersRouteChildren: PickupOrdersRouteChildren = {
  PickupOrdersIndexRoute: PickupOrdersIndexRoute,
  PickupOrdersOrderIdStepIdRoute: PickupOrdersOrderIdStepIdRouteWithChildren,
}

const PickupOrdersRouteWithChildren = PickupOrdersRoute._addFileChildren(
  PickupOrdersRouteChildren,
)

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute
  "/overview": typeof OverviewRouteWithChildren
  "/pickup-orders": typeof PickupOrdersRouteWithChildren
  "/profile": typeof ProfileRoute
  "/overview/$orderId": typeof OverviewOrderIdRoute
  "/pickup-orders/": typeof PickupOrdersIndexRoute
  "/pickup-orders/$orderId/$stepId": typeof PickupOrdersOrderIdStepIdRouteWithChildren
  "/pickup-orders/$orderId/$stepId/edit": typeof PickupOrdersOrderIdStepIdEditRoute
  "/pickup-orders/$orderId/$stepId/": typeof PickupOrdersOrderIdStepIdIndexRoute
  "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit": typeof PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute
  "/overview": typeof OverviewRouteWithChildren
  "/profile": typeof ProfileRoute
  "/overview/$orderId": typeof OverviewOrderIdRoute
  "/pickup-orders": typeof PickupOrdersIndexRoute
  "/pickup-orders/$orderId/$stepId/edit": typeof PickupOrdersOrderIdStepIdEditRoute
  "/pickup-orders/$orderId/$stepId": typeof PickupOrdersOrderIdStepIdIndexRoute
  "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit": typeof PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/": typeof IndexRoute
  "/overview": typeof OverviewRouteWithChildren
  "/pickup-orders": typeof PickupOrdersRouteWithChildren
  "/profile": typeof ProfileRoute
  "/overview/$orderId": typeof OverviewOrderIdRoute
  "/pickup-orders/": typeof PickupOrdersIndexRoute
  "/pickup-orders/$orderId/$stepId": typeof PickupOrdersOrderIdStepIdRouteWithChildren
  "/pickup-orders/$orderId/$stepId/edit": typeof PickupOrdersOrderIdStepIdEditRoute
  "/pickup-orders/$orderId/$stepId/": typeof PickupOrdersOrderIdStepIdIndexRoute
  "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit": typeof PickupOrdersOrderIdStepIdGroupsGroupIdPackagesPackageIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | "/"
    | "/overview"
    | "/pickup-orders"
    | "/profile"
    | "/overview/$orderId"
    | "/pickup-orders/"
    | "/pickup-orders/$orderId/$stepId"
    | "/pickup-orders/$orderId/$stepId/edit"
    | "/pickup-orders/$orderId/$stepId/"
    | "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit"
  fileRoutesByTo: FileRoutesByTo
  to:
    | "/"
    | "/overview"
    | "/profile"
    | "/overview/$orderId"
    | "/pickup-orders"
    | "/pickup-orders/$orderId/$stepId/edit"
    | "/pickup-orders/$orderId/$stepId"
    | "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit"
  id:
    | "__root__"
    | "/"
    | "/overview"
    | "/pickup-orders"
    | "/profile"
    | "/overview/$orderId"
    | "/pickup-orders/"
    | "/pickup-orders/$orderId/$stepId"
    | "/pickup-orders/$orderId/$stepId/edit"
    | "/pickup-orders/$orderId/$stepId/"
    | "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  OverviewRoute: typeof OverviewRouteWithChildren
  PickupOrdersRoute: typeof PickupOrdersRouteWithChildren
  ProfileRoute: typeof ProfileRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  OverviewRoute: OverviewRouteWithChildren,
  PickupOrdersRoute: PickupOrdersRouteWithChildren,
  ProfileRoute: ProfileRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/overview",
        "/pickup-orders",
        "/profile"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/overview": {
      "filePath": "overview.tsx",
      "children": [
        "/overview/$orderId"
      ]
    },
    "/pickup-orders": {
      "filePath": "pickup-orders.tsx",
      "children": [
        "/pickup-orders/",
        "/pickup-orders/$orderId/$stepId"
      ]
    },
    "/profile": {
      "filePath": "profile.tsx"
    },
    "/overview/$orderId": {
      "filePath": "overview.$orderId.tsx",
      "parent": "/overview"
    },
    "/pickup-orders/": {
      "filePath": "pickup-orders.index.tsx",
      "parent": "/pickup-orders"
    },
    "/pickup-orders/$orderId/$stepId": {
      "filePath": "pickup-orders.$orderId.$stepId.tsx",
      "parent": "/pickup-orders",
      "children": [
        "/pickup-orders/$orderId/$stepId/edit",
        "/pickup-orders/$orderId/$stepId/",
        "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit"
      ]
    },
    "/pickup-orders/$orderId/$stepId/edit": {
      "filePath": "pickup-orders.$orderId.$stepId.edit.tsx",
      "parent": "/pickup-orders/$orderId/$stepId"
    },
    "/pickup-orders/$orderId/$stepId/": {
      "filePath": "pickup-orders.$orderId.$stepId.index.tsx",
      "parent": "/pickup-orders/$orderId/$stepId"
    },
    "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit": {
      "filePath": "pickup-orders.$orderId.$stepId.groups.$groupId.packages_.$packageId.edit.tsx",
      "parent": "/pickup-orders/$orderId/$stepId"
    }
  }
}
ROUTE_MANIFEST_END */
