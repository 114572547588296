import { BoxFragment, OrderFragment, OrderStatus } from "@gen/graphql"
import { Card, CardContent, IconButton, Typography } from "@northvolt/ui"
import { Stack } from "@northvolt/ui"
import { IconArrowLeft } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { ModuleSummary } from "../ModuleSummary/ModuleSummary"
import { PackSummary } from "../PackSummary/PackSummary"

type GroupProps = {
  stepId: string
  order: OrderFragment
  groupId: string
  selectedId?: string
}

export const GroupSummaryList = ({
  stepId,
  order,
  groupId,
  selectedId,
}: GroupProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const group: BoxFragment[] = []
  order.boxes.forEach((p) => {
    if (p.group === groupId) {
      group.push(p)
    }
  })

  const isPacks = group[0].items[0]?.__typename === "Pack"
  const isModules = group[0].items[0]?.__typename === "Module"
  const groupType = isModules ? t("components.basics.modules") : t("components.basics.packs")
  let titleText = isModules ? t("components.basics.module") : t("components.basics.pack")
  if (group.length > 1) {
    titleText = t("components.GroupSummaryList.title", { type: groupType })
  }

  return (
    <Card>
      <CardContent>
        <Typography>
          <IconButton
            style={{ marginRight: 4 }}
            size="small"
            onClick={() => {
              navigate({
                to: "/pickup-orders/$orderId/$stepId",
                params: {
                  stepId: stepId,
                  orderId: order.id,
                },
              })
            }}
          >
            <IconArrowLeft />{" "}
          </IconButton>
          {titleText}
        </Typography>
        {isPacks && (
          <Stack mt={4} gap={2}>
            {group.map((g) => (
              <PackSummary
                key={g.id}
                order={order}
                orderBox={g}
                selectedId={selectedId}
                editable={
                  order.status === OrderStatus.Draft || order.status === OrderStatus.Pending
                }
              />
            ))}
          </Stack>
        )}
        {isModules && (
          <Stack mt={4} gap={2}>
            {group.map((g) => (
              <ModuleSummary
                key={g.id}
                order={order}
                orderBox={g}
                selectedId={selectedId}
                editable={
                  order.status === OrderStatus.Draft || order.status === OrderStatus.Pending
                }
              />
            ))}
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}
