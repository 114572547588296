import { EditModule } from "@components/PickupOrder/Components/EditModule/EditModule"
import { EditPack } from "@components/PickupOrder/Components/EditPack/EditPack"
import { GroupSummaryList } from "@components/PickupOrder/Components/GroupSummaryList/GroupSummaryList"
import { BoxTypeFragment, ModuleTypeFragment, OrderFragment, PackTypeFragment } from "@gen/graphql"
import { Card, Grid } from "@northvolt/ui"
import { JSX } from "react"

type EditBoxSmallVolumeStepProps = {
  order: OrderFragment
  stepId: string
  groupId: string
  selectedId: string
  boxTypes: BoxTypeFragment[]
  packTypes: PackTypeFragment[]
  moduleTypes: ModuleTypeFragment[]
}

export const EditBoxStep = ({
  order,
  stepId,
  groupId,
  selectedId,
  boxTypes,
  packTypes,
  moduleTypes,
}: EditBoxSmallVolumeStepProps): JSX.Element => {
  const box = order.boxes.find((b) => b.id === selectedId)
  if (!box) {
    return <Card>Box not found</Card>
  }
  const isPack = box.items[0]?.__typename === "Pack"
  const isModule = box.items[0]?.__typename === "Module"

  return (
    <Grid container spacing={2} data-testid="edit-box-step">
      <Grid xs={12} md={6}>
        {isPack && (
          <EditPack
            stepId={stepId}
            order={order}
            box={box}
            boxTypes={boxTypes}
            packTypes={packTypes}
          />
        )}
        {isModule && (
          <EditModule
            stepId={stepId}
            order={order}
            box={box}
            boxTypes={boxTypes}
            moduleTypes={moduleTypes}
          />
        )}
      </Grid>
      <Grid xs={12} md={6}>
        <GroupSummaryList stepId={stepId} order={order} groupId={groupId} selectedId={selectedId} />
      </Grid>
    </Grid>
  )
}
