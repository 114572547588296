import { LazyQueryExecFunction } from "@apollo/client"
import { ListOrderBoxes } from "@components/PickupOrder/Components/ListOrderBoxes/ListOrderBoxes"
import {
  Exact,
  OrderListFragment,
  OrderStatus,
  TrackingLabelQuery,
  useDeleteOrderMutation,
  useTrackingLabelLazyQuery,
} from "@gen/graphql"
import { Theme, useMediaQuery } from "@mui/material"
import { styled } from "@mui/material/styles"
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  IconButton,
  Drawer as MuiDrawer,
  Stack,
  Typography,
  useSnackbar,
} from "@northvolt/ui"
import { ConfirmationDialog, DownloadButton, IconPen, IconTrashCan, IconXmark } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { JSX, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

dayjs.extend(customParseFormat)

type PickupOrderListProps = {
  orders: OrderListFragment[]
  selectedOrderId?: string
}

const sumOrderNetWeight = (order: OrderListFragment): number =>
  order.boxes.reduce(
    (acc, box) =>
      acc +
      box.items.reduce((a, i) => {
        if (i.__typename === "Pack") {
          return a + i.netWeight
        }
        if (i.__typename === "Module") {
          return a + i.netWeight * (i.green + i.yellow + i.red)
        }
        return a
      }, 0),
    0,
  )

const sumOrderGrossWeight = (order: OrderListFragment): number =>
  sumOrderNetWeight(order) + order.boxes.reduce((acc, box) => acc + box.boxType.netWeight, 0)

const sumOrderItems = (order: OrderListFragment): number => order.boxes.length

export const OverviewDrawer = ({ orders, selectedOrderId }: PickupOrderListProps): JSX.Element => {
  const navigate = useNavigate()
  const [selectedOrder, setOpen] = useState<OrderListFragment | undefined>()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const toggleDrawer = (order: OrderListFragment | undefined): void => {
    setOpen(order)
    if (order) {
      navigate({
        to: "/overview/$orderId",
        params: {
          orderId: order.id,
        },
      })
    } else {
      navigate({
        to: "/overview",
      })
    }
  }

  useEffect(() => {
    if (selectedOrderId) {
      const order = orders.find((o) => o.id === selectedOrderId)
      if (order) {
        setOpen(order)
      }
    }
  }, [selectedOrderId, orders])

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      open={selectedOrder !== undefined}
      onClose={() => toggleDrawer(undefined)}
    >
      {selectedOrder && <DrawerContent order={selectedOrder} toggleDrawer={toggleDrawer} />}
    </Drawer>
  )
}

const useGetTrackingLabel = (
  orderID: string,
): LazyQueryExecFunction<TrackingLabelQuery, Exact<{ orderID: string }>> => {
  const [getTrackingLabelFn] = useTrackingLabelLazyQuery({
    variables: {
      orderID: orderID,
    },
  })
  return getTrackingLabelFn
}

type DrawerContentProps = {
  order: OrderListFragment
  toggleDrawer: (order: OrderListFragment | undefined) => void
}

const DrawerContent = ({ order, toggleDrawer }: DrawerContentProps): JSX.Element => {
  const navigate = useNavigate()
  const [deleteOrder] = useDeleteOrderMutation()
  const updateSnackbar = useSnackbar()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { t } = useTranslation()

  const handleDelete = (): void => {
    deleteOrder({
      variables: { input: { pickupOrderId: order.id, etag: order.etag } },
      update: (cache) => {
        cache.evict({ id: `PickupOrder:${order.id}` })
        cache.gc()
      },
    })
      .then((result) => {
        if (result) {
          navigate({ to: "/overview" })
          updateSnackbar({
            message: t("components.OverviewDrawer.orderDeletedSuccess"),
            severity: "success",
          })
        } else {
          updateSnackbar({
            message: t("components.OverviewDrawer.orderDeletedFail"),
            severity: "error",
          })
        }
      })
      .catch((e) => {
        updateSnackbar({
          message: t("components.OverviewDrawer.orderDeleteCatch") + e,
          severity: "error",
        })
      })
  }

  const disableEdit = order.status === OrderStatus.AwaitingApproval

  return (
    <Box paddingX={3} paddingY={4}>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid sm={10} md={10}>
          <Typography variant="headlineSmall">{t("components.OverviewDrawer.header")}</Typography>
          <Typography color="secondary">{order?.pickupDate}</Typography>
        </Grid>
        <Grid sm={2} md={2} marginTop={1}>
          <Stack direction="row" gap={1} justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId",
                  params: {
                    orderId: order.id,
                    stepId: "1",
                  },
                })
              }}
              disabled={disableEdit}
            >
              <IconPen />
            </IconButton>
            {(order.status === OrderStatus.Draft || order.status === OrderStatus.Pending) && (
              <IconButton size="small" onClick={() => setOpenDeleteDialog(true)}>
                <IconTrashCan />
              </IconButton>
            )}
            <ConfirmationDialog
              open={openDeleteDialog}
              title={t("components.ConfirmationDialog.title.deleteOrder")}
              confirmButtonText={t("components.ConfirmationDialog.confirm")}
              cancelButtonText={t("components.ConfirmationDialog.cancel")}
              onConfirm={handleDelete}
              onCancel={() => setOpenDeleteDialog(false)}
            ></ConfirmationDialog>
            <IconButton size="small" onClick={() => toggleDrawer(undefined)}>
              <IconXmark />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} marginY={2}>
        <Grid xs={12} sm={6}>
          <Typography>{t("components.OverviewDrawer.totalPackages")}</Typography>
          <Typography variant="headlineSmall" data-testid="overview-drawer-order-items">
            {sumOrderItems(order)}
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography>{t("components.basics.grossWeight")}</Typography>
          <Typography variant="headlineSmall" data-testid="overview-drawer-order-weight">
            {sumOrderGrossWeight(order)} kg
          </Typography>
        </Grid>
      </Grid>
      <Stack gap={2}>
        {(order.contacts.length > 0 || order.subscribers.length > 0) && (
          <Box mt={1} bgcolor={"background.default"} p={1} borderRadius={1}>
            <Grid container spacing={2}>
              {order.contacts.length > 0 && (
                <Grid xs={12} sm={6}>
                  <Typography variant="captionSmall">
                    {t("components.Contacts.addedContact")}
                  </Typography>
                  {order.contacts.map((contact) => (
                    <Typography key={contact.id}>
                      {contact.contactName} - {contact.phone}
                    </Typography>
                  ))}
                </Grid>
              )}
              {order.subscribers.length > 0 && (
                <Grid xs={12} sm={6}>
                  <Typography variant="captionSmall">
                    {t("components.Contacts.subscribers")}
                  </Typography>
                  {order.subscribers.map((subscriber) => (
                    <Typography key={subscriber.id}>{subscriber.email}</Typography>
                  ))}
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {order.trackingLabelAvailable && (
          <DownloadButton
            getTrackingLabelFn={useGetTrackingLabel}
            orderID={order.id}
            type={"expanded"}
          />
        )}
        {!order.trackingLabelAvailable && (
          <Typography sx={{ marginBottom: 3 }}>
            {t("components.OverviewDrawer.noTrackingLabel")}
          </Typography>
        )}
        {disableEdit && (
          <Alert severity="error">
            <AlertTitle>{t("components.OverviewDrawer.noEditAlert.title")}</AlertTitle>
            {t("components.OverviewDrawer.noEditAlert.message")}
          </Alert>
        )}
        <ListOrderBoxes order={order} editable={false} stepId={"2"} />
      </Stack>
    </Box>
  )
}

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  [`${theme.breakpoints.down("sm")}`]: {
    "& .MuiDrawer-paper": {
      borderRadius: "16px 16px 0 0",
    },
  },
  [`${theme.breakpoints.up("sm")}`]: {
    "> .MuiPaper-root": {
      width: "60vw",
      maxWidth: "523px",
    },
  },
}))
