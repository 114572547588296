import {
  BatteryStatus as BatteryStatusType,
  BoxFragment,
  OrderFragment,
  OrderStatus,
  PackFragment,
  useDeleteBoxMutation,
} from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import {
  BatteryStatus,
  ConfirmationDialog,
  IconBox,
  IconPen,
  IconTrashCan,
  readableEnum,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

type PackProps = {
  order: OrderFragment
  orderBox: BoxFragment
  selectedId?: string
  editable: boolean
}

export const PackSummary = ({ order, orderBox, selectedId, editable }: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteBox] = useDeleteBoxMutation()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleDelete = (): void => {
    deleteBox({
      variables: {
        input: {
          etag: order.etag,
          pickupOrderId: order.id,
          boxId: orderBox.id,
        },
      },
    })
    setOpenDeleteDialog(false)
  }

  type Pill = [string, string, BatteryStatusType, number]

  const packs: PackFragment[] = orderBox.items.filter(
    (p): p is PackFragment => p.__typename === "Pack",
  )

  const pills: Pill[] = packs.reduce((acc: Pill[], cur) => {
    if (cur.green > 0) {
      acc.push([
        cur.packType.id,
        cur.packType.displayName,
        BatteryStatusType.Green,
        cur.green,
      ] as Pill)
    }
    if (cur.yellow > 0) {
      acc.push([
        cur.packType.id,
        cur.packType.displayName,
        BatteryStatusType.Yellow,
        cur.yellow,
      ] as Pill)
    }
    if (cur.red > 0) {
      acc.push([cur.packType.id, cur.packType.displayName, BatteryStatusType.Red, cur.red] as Pill)
    }
    return acc
  }, [])

  const packsCount = packs.reduce((acc, cur) => acc + cur.green + cur.yellow + cur.red, 0)

  const disableEdit = order.status === OrderStatus.AwaitingApproval

  return (
    <>
      <PackCard selected={selectedId === orderBox.id} variant="outlined" sx={{ padding: 1 }}>
        <Box sx={{ p: 1 }}>
          <IconBox />
        </Box>
        <Box flexGrow={1}>
          <Typography>{orderBox.boxType.displayName}</Typography>
          <Typography>{packsCount + " " + t("components.basics.pack")}</Typography>
          {/* <BatteryStatus pill status={pack.batteryStatus} label={pack.packType.displayName} /> */}
          {pills.map((p) => (
            <BatteryStatus pill status={p[2]} key={p[0] + p[2]} label={readableEnum(p[1])} />
          ))}
        </Box>
        {editable && (
          <Box sx={{ p: 1 }}>
            <IconButton
              size="small"
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                  params: {
                    orderId: order.id,
                    stepId: "2",
                    groupId: orderBox.group,
                    packageId: orderBox.id,
                  },
                })
              }}
              disabled={disableEdit}
            >
              <IconPen />
            </IconButton>
          </Box>
        )}
        {editable && (
          <Box>
            <IconButton
              data-testid="pack-summary-delete-button"
              size="small"
              onClick={() => setOpenDeleteDialog(true)} // Show the confirmation dialog on click
              disabled={disableEdit}
            >
              <IconTrashCan />
            </IconButton>
          </Box>
        )}
      </PackCard>
      <ConfirmationDialog
        open={openDeleteDialog}
        title={t("components.ConfirmationDialog.title.deletePackage")}
        confirmButtonText={t("components.ConfirmationDialog.confirm")}
        cancelButtonText={t("components.ConfirmationDialog.cancel")}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteDialog(false)}
      ></ConfirmationDialog>
    </>
  )
}

const PackCard = styled(Card)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.background.default : theme.palette.background.paper};
`
