import { LazyQueryExecFunction } from "@apollo/client"
import {
  Exact,
  OrderListFragment,
  TrackingLabelQuery,
  useTrackingLabelLazyQuery,
} from "@gen/graphql"
import { Button, Card, Typography } from "@northvolt/ui"
import { GridColDef, GridColumnResizeParams, GridRenderCellParams } from "@northvolt/ui/DataGrid"
import {
  DownloadButton,
  OldStyledDataGrid,
  StatusChip,
  StyledDataGrid,
  sumOrderGrossWeight,
  sumOrderNetWeight,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { FilterPanel } from "./FilterPanel"

type PickupOrderListProps = {
  orders: OrderListFragment[]
}

const sumOrderItems = (order: OrderListFragment): number => order.boxes.length

const useGetTrackingLabel = (
  orderID: string,
): LazyQueryExecFunction<TrackingLabelQuery, Exact<{ orderID: string }>> => {
  const [getTrackingLabelFn] = useTrackingLabelLazyQuery({
    variables: {
      orderID: orderID,
    },
  })
  return getTrackingLabelFn
}

export const PickupOrderList = ({ orders }: PickupOrderListProps): JSX.Element => {
  const navigate = useNavigate()

  const toggleDrawer = (orderId: string | undefined): void => {
    if (orderId) {
      navigate({
        to: "/overview/$orderId",
        params: {
          orderId: orderId,
        },
      })
    } else {
      navigate({
        to: "/overview",
      })
    }
  }

  const [filteredOrders, setFilteredOrders] = useState<OrderListFragment[]>(orders)
  const handleColumnWidthChange = (params: GridColumnResizeParams): void => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.field === params.colDef.field ? { ...col, width: params.width } : col,
      ),
    )
  }
  const columnFileds = [
    {
      field: "orderID",
      headerName: "Order ID",
      sortable: false,
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <StatusChip status={params.value} label={params.value} />
      ),
      width: 130,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      sortable: false,
      width: 120,
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      sortable: false,
      width: 100,
    },
    {
      field: "pickupLocation",
      headerName: "Pickup Location",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <OldStyledDataGrid>{params.value}</OldStyledDataGrid>
      },
      width: 220,
    },
    {
      field: "orderContent",
      headerName: "Order Content",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <OldStyledDataGrid>
            <Typography>{params.value} packages</Typography>
            <Button
              variant="text"
              onClick={() => {
                toggleDrawer(params.row.id)
              }}
            >
              Show more
            </Button>
          </OldStyledDataGrid>
        )
      },
      width: 120,
    },
    {
      field: "netWeight",
      headerName: "Net Weight",
      sortable: false,
      width: 110,
    },
    {
      field: "grossWeight",
      headerName: "Gross Weight",
      sortable: false,
      width: 110,
    },
    {
      field: "trackingLabel",
      headerName: "Tracking Label",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row?.trackingLabelAvailable) {
          return (
            <DownloadButton
              getTrackingLabelFn={useGetTrackingLabel}
              orderID={params.row.id}
              type="simple"
            />
          )
        }
      },
      width: 120,
    },
  ]
  const [columns, setColumns] = useState<GridColDef[]>(columnFileds)

  return (
    <Card>
      <FilterPanel orders={orders} onFilterChange={setFilteredOrders} />
      <StyledDataGrid
        onColumnWidthChange={handleColumnWidthChange}
        rowHeight={75}
        disableColumnMenu
        initialState={{
          density: "comfortable",
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100, { value: -1, label: "All" }]}
        pagination
        columns={columns}
        rows={filteredOrders
          .map((order) => ({
            id: order.id,
            status: order.status,
            pickupDate: order.pickupDate,
            pickupLocation: order.pickupLocation?.displayName,
            orderContent: sumOrderItems(order),
            netWeight: sumOrderNetWeight(order),
            grossWeight: sumOrderGrossWeight(order),
            orderID: order.orderID,
            createdBy: order.createdBy?.firstName + " " + order.createdBy?.lastName,
            trackingLabelAvailable: order.trackingLabelAvailable,
          }))
          .reverse()}
      />
    </Card>
  )
}
