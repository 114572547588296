import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@northvolt/ui"
import { useState } from "react"

type ConfirmationDialogProps = {
  open: boolean
  title: string
  message?: string
  onConfirm: () => void
  onCancel: () => void
  confirmButtonText: string
  cancelButtonText: string
  confirmButtonColor?:
    | "primary"
    | "error"
    | "secondary"
    | "inherit"
    | "success"
    | "info"
    | "warning"
  cancelButtonColor?: "primary" | "error" | "secondary" | "inherit" | "success" | "info" | "warning"
}

export const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "secondary",
  cancelButtonColor = "secondary",
}: ConfirmationDialogProps): React.JSX.Element => {
  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle style={{ minWidth: "444px" }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <DialogContentText id="dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button onClick={onCancel} color={cancelButtonColor} variant="text" autoFocus>
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} color={confirmButtonColor}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type ConfirmationDialogWithTextfieldProps = {
  orderID: string
  open: boolean
  title: string
  message?: string
  textFieldLabel: string
  onConfirm: (textFieldValue: string, orderID: string) => void
  onCancel: () => void
  confirmButtonText: string
  cancelButtonText: string
  confirmButtonColor?:
    | "primary"
    | "error"
    | "secondary"
    | "inherit"
    | "success"
    | "info"
    | "warning"
  cancelButtonColor?: "primary" | "error" | "secondary" | "inherit" | "success" | "info" | "warning"
}

export const ConfirmationDialogWithTextfield = ({
  orderID,
  open,
  title,
  message,
  textFieldLabel,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "secondary",
  cancelButtonColor = "secondary",
}: ConfirmationDialogWithTextfieldProps): React.JSX.Element => {
  const [textFieldValue, setTextFieldValue] = useState("")

  const handleConfirm = async (): Promise<void> => {
    onConfirm(textFieldValue, orderID)
  }

  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle style={{ minWidth: "444px" }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <TextField
          label={textFieldLabel}
          variant="outlined"
          fullWidth
          sx={{ mt: 1 }}
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          data-testid="confirmation-dialog-input"
        />
        <DialogContentText id="dialog-description" sx={{ ml: 1 }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button onClick={onCancel} color={cancelButtonColor} variant="text" autoFocus>
          {cancelButtonText}
        </Button>
        <Button onClick={handleConfirm} color={confirmButtonColor}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
