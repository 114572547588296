import { CardHeader } from "@mui/material"
import { Box, Button, Card, CardContent, Stack } from "@northvolt/ui"
import { IconPlus } from "@shared"
import { JSX } from "react"

type AddItemSmallVolumeProps = {
  orderId: string
  setType: (state: "pack" | "module") => void
}

export const AddItemSmallVolume = ({ setType }: AddItemSmallVolumeProps): JSX.Element => {
  return (
    <Card>
      <CardHeader title="Add items to your pickup" />
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Button
              color="secondary"
              startIcon={<IconPlus />}
              variant="outlined"
              fullWidth
              onClick={() => setType("module")}
            >
              Add module
            </Button>
            <Button
              color="secondary"
              startIcon={<IconPlus />}
              variant="outlined"
              fullWidth
              onClick={() => setType("pack")}
            >
              Add pack
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  )
}
