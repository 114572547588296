import {
  InputAdornment,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material"
import { Box, Checkbox, Chip, ListItemText } from "@northvolt/ui"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { readableEnum } from "../../lib/typography"
import { IconBox } from "../Icons/Icons"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const VISIBLE_ITEMS = 4.5
const WIDTH = 250
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * VISIBLE_ITEMS + ITEM_PADDING_TOP,
      width: WIDTH,
    },
  },
}

type DropdownMenuModuleProps = {
  fields: { moduleTypeId: string }[]
  moduleTypes: { id?: any | null; displayName: string }[]
  handleChange: (event: SelectChangeEvent<string[]>) => void
}

export const DropdownMenuModule = ({
  fields,
  moduleTypes,
  handleChange,
}: DropdownMenuModuleProps): JSX.Element => {
  const { t } = useTranslation("components")

  return (
    <MuiSelect
      multiple
      fullWidth
      endAdornment={
        <InputAdornment position="start">
          <Box paddingRight={3}>
            <IconBox />
          </Box>
        </InputAdornment>
      }
      value={fields.map((m) => m.moduleTypeId)}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              const type = moduleTypes.find((m) => m.id === value)
              return <Chip key={value} label={readableEnum(type?.displayName ?? "unknown")} />
            })}
          </Box>
        )
      }}
      onChange={handleChange}
      input={<OutlinedInput label={t("Dropdown.selectModule")} data-testid="select-module-type" />}
      MenuProps={MenuProps}
    >
      {moduleTypes.map((m) => (
        <MenuItem
          key={m.id}
          value={m.id}
          className="select-module-type-item"
          data-testid={`select-module-type-${m.id}`}
        >
          <Checkbox checked={fields.find((f) => f.moduleTypeId === m.id) !== undefined} />
          <ListItemText primary={readableEnum(m.displayName)} />
        </MenuItem>
      ))}
    </MuiSelect>
  )
}

type DropdownMenuPackProps = {
  fields: { packTypeId: string }[]
  packTypes: { id?: any | null; displayName: string }[]
  handleChange: (event: SelectChangeEvent<string[]>) => void
  maxPacks?: number
}

export const DropdownMenuPack = ({
  fields,
  packTypes,
  handleChange,
  maxPacks = 4,
}: DropdownMenuPackProps): JSX.Element => {
  const { t } = useTranslation("components")

  return (
    <MuiSelect
      multiple
      fullWidth
      endAdornment={
        <InputAdornment position="start">
          <Box paddingRight={3}>
            <IconBox />
          </Box>
        </InputAdornment>
      }
      value={fields.map((m) => m.packTypeId)}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              const type = packTypes.find((m) => m.id === value)
              return <Chip key={value} label={readableEnum(type?.displayName ?? "unknown")} />
            })}
          </Box>
        )
      }}
      onChange={(event) => {
        if (event.target.value.length <= maxPacks) {
          handleChange(event)
        }
      }}
      input={<OutlinedInput label={t("Dropdown.selectPack")} data-testid="select-pack-type" />}
      MenuProps={MenuProps}
    >
      {packTypes.map((m) => (
        <MenuItem
          key={m.id}
          value={m.id}
          className="select-pack-type-item"
          data-testid={`select-pack-type-${m.id}`}
          disabled={fields.length >= maxPacks && !fields.find((f) => f.packTypeId === m.id)}
        >
          <Checkbox checked={fields.find((f) => f.packTypeId === m.id) !== undefined} />
          <ListItemText primary={readableEnum(m.displayName)} />
        </MenuItem>
      ))}
    </MuiSelect>
  )
}
