/* eslint-disable no-param-reassign */
import {
  BoxFragment,
  BoxTypeFragment,
  ModuleTypeFragment,
  OrderFragment,
  OrderStatus,
  useUpdateBoxMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { InputAdornment, InputLabel, SelectChangeEvent } from "@mui/material"
import { Box, Button, Card, CardContent, Stack, Typography, useSnackbar } from "@northvolt/ui"
import { AutoCompleteInput, CardActions, DropdownMenuModule, IconBox, RenderError } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useEffect } from "react"
import { DefaultValues, FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { moduleSchema } from "../AddBox/AddModule"
import { Module } from "../Module/Module"

type EditModuleProps = {
  stepId: string
  order: OrderFragment
  box: BoxFragment
  moduleTypes: ModuleTypeFragment[]
  boxTypes: BoxTypeFragment[]
}

export const EditModule = ({
  stepId,
  order,
  box,
  moduleTypes,
  boxTypes,
}: EditModuleProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()
  const [updateBox, { error }] = useUpdateBoxMutation()
  const formSchema = z.object({
    boxTypeId: z.string().refine((id) => boxTypes.some((p) => p.id === id)),
    modules: z.array(moduleSchema).min(1, { message: t("components.EditModule.needOneModule") }),
  })

  useEffect(() => {
    if (error?.message) {
      updateSnackbar({
        message: error.message,
        severity: "error",
      })
    }
  }, [error, updateSnackbar])

  type ZodInputTypes = z.infer<typeof formSchema>
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    boxTypeId: box.boxType.id,
    modules: box.items.map((m) => {
      if (m.__typename !== "Module") {
        throw new Error("Invalid module type")
      }
      return {
        moduleId: m.id,
        moduleTypeId: m.moduleType.id,
        displayName: m.moduleType.displayName,
        netWeight: m.netWeight,
        green: m.green,
        yellow: m.yellow,
        red: m.red,
      }
    }),
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isSubmitting },
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "modules",
  })

  useEffect(() => {
    if (box) {
      reset({
        boxTypeId: box.boxType.id,
        modules: box.items.map((m) => {
          if (m.__typename !== "Module") {
            throw new Error("Invalid module type")
          }
          return {
            moduleId: m.id,
            moduleTypeId: m.moduleType.id,
            displayName: m.moduleType.displayName,
            netWeight: m.netWeight,
            green: m.green,
            yellow: m.yellow,
            red: m.red,
          }
        }),
      })
    }
  }, [box, reset])

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    const removedModules = fields.filter((m) => !value.includes(m.moduleTypeId))
    removedModules.forEach((v) => {
      const removeIndex = fields.findIndex((f) => f.moduleTypeId === v.moduleTypeId)
      if (removeIndex !== -1) {
        remove(removeIndex)
      }
    })

    if (typeof value === "object") {
      value.forEach((newId) => {
        const existingIndex = fields.findIndex((m) => m.moduleTypeId === newId)
        if (existingIndex === -1) {
          const moduleType = moduleTypes.find((m) => m.id === newId)
          if (moduleType !== undefined) {
            append({
              moduleId: newId,
              moduleTypeId: moduleType?.id,
              displayName: moduleType?.displayName ?? "",
              netWeight: moduleType?.defaultNetWeight ?? 0,
              green: 0,
              yellow: 0,
              red: 0,
            })
          }
        }
      })
    }
  }

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes): Promise<void> => {
    return updateBox({
      variables: {
        input: {
          etag: order.etag,
          pickupOrderId: order.id,
          boxId: box.id,
          boxTypeId: data.boxTypeId,
          modules: data.modules.map((m) => ({
            moduleType: {
              internalID: m.moduleTypeId,
              displayName: m.displayName,
            },
            moduleId: m.moduleId,
            netWeight: m.netWeight,
            green: m.green,
            yellow: m.yellow,
            red: m.red,
          })),
          packs: [],
        },
      },
    }).then(() => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        params: {
          orderId: order.id,
          stepId: stepId,
        },
      })
    })
  }

  const disableEdit = order.status === OrderStatus.AwaitingApproval

  return (
    <FormProvider {...methods}>
      <Card>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <CardActions>
              <Typography variant="headlineSmall">
                <IconBox /> {box.boxType.displayName}
              </Typography>
              <Stack direction="row" gap={2}>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate({
                      to: "/pickup-orders/$orderId/$stepId",
                      params: {
                        orderId: order.id,
                        stepId: stepId,
                      },
                    })
                  }}
                >
                  {t("components.basics.cancel")}
                </Button>
                <Button
                  data-testid={
                    isSubmitSuccessful
                      ? "edit-module-submit-button-success"
                      : "edit-module-submit-button"
                  }
                  disabled={
                    isSubmitting ||
                    disableEdit ||
                    (order.status !== OrderStatus.Draft && order.status !== OrderStatus.Pending)
                  }
                  type="submit"
                >
                  {isSubmitSuccessful ? t("components.basics.saved") : t("components.basics.save")}
                </Button>
              </Stack>
            </CardActions>
            <RenderError control={control} name={`modules`} />
            <Stack spacing={2}>
              <AutoCompleteInput
                control={control}
                fullWidth
                name="boxTypeId"
                // disablePortal makes it so the autocomplete dropdown appears within the react part of the DOM. Important to ensure tests work correctly
                disablePortal={true}
                label={t("components.basics.selectBoxType.label")}
                endAdornment={
                  <InputAdornment position="start">
                    <IconBox />
                  </InputAdornment>
                }
                options={boxTypes.map((p) => ({
                  value: p.id,
                  label: p.displayName,
                }))}
              />

              <Box>
                <InputLabel>Choose module type</InputLabel>
                <DropdownMenuModule
                  fields={fields}
                  moduleTypes={moduleTypes}
                  handleChange={handleChange}
                />
              </Box>

              {fields.map((module, index) => (
                <Module key={module.id} index={index} module={module} remove={remove} />
              ))}
            </Stack>
          </CardContent>
        </form>
      </Card>
    </FormProvider>
  )
}
