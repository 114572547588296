import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** This is using the time.RFC3339Nano format, "2006-01-02T15:04:05.999999999Z07:00" */
  Time: { input: any; output: any; }
  /** Just a UUID scalar */
  UUID: { input: any; output: any; }
  /** The Upload type represents a file that is being uploaded to the system */
  Upload: { input: any; output: any; }
};

export type AcceptPrivacyPolicyInput = {
  /** The date / version of the Privacy policy the user is accepting */
  acceptedAt: Scalars['String']['input'];
};

export type AddContactInput = {
  contactName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

export type AddLocationAndDateInput = {
  date: Scalars['String']['input'];
  etag: Scalars['UUID']['input'];
  locationId: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

export type AddModuleInput = {
  /** The instance of box to add the module to */
  boxType: BoxTypeInput;
  /** The number of modules to add, must be greater than 0 */
  count: Scalars['Int']['input'];
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  modules: Array<AddModulePartInput>;
  pickupOrderId: Scalars['UUID']['input'];
};

/** Add a module to a pickup order */
export type AddModulePartInput = {
  fileIDs: Array<Scalars['UUID']['input']>;
  /** The number of green cells in the module  */
  green: Scalars['Int']['input'];
  /** The type of module to add */
  moduleType: ModuleTypeInput;
  /** The net weight of the module, must be greater than 0 */
  netWeight: Scalars['Int']['input'];
  /** The number of red cells in the module  */
  red: Scalars['Int']['input'];
  /** The number of yellow cells in the module  */
  yellow: Scalars['Int']['input'];
};

export type AddPackInput = {
  boxType: BoxTypeInput;
  count: Scalars['Int']['input'];
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  packs: Array<AddPackPartInput>;
  pickupOrderId: Scalars['UUID']['input'];
};

export type AddPackPartInput = {
  fileIDs: Array<Scalars['UUID']['input']>;
  /** The number of green cells in the pack  */
  green: Scalars['Int']['input'];
  /** The net weight of the pack, must be greater than 0 */
  netWeight: Scalars['Int']['input'];
  /** The type of pack to add */
  packType: PackTypeInput;
  /** The number of red cells in the pack  */
  red: Scalars['Int']['input'];
  /** The number of yellow cells in the pack  */
  yellow: Scalars['Int']['input'];
};

export type AddSubscriberInput = {
  email: Scalars['String']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

/** The state of a battery pack or module */
export enum BatteryStatus {
  /** No problems with the battery */
  Green = 'Green',
  /** Problems with the battery */
  Red = 'Red',
  /** Unknown state of the battery */
  Unknown = 'Unknown',
  /** Potential problems with the battery */
  Yellow = 'Yellow'
}

/** An instance of a BoxType */
export type Box = {
  __typename?: 'Box';
  /** The type of box */
  boxType: BoxType;
  /** The group this box belongs to */
  group: Scalars['UUID']['output'];
  /** The unique identifier for the Box. Used to reference the Box in the system. */
  id: Scalars['UUID']['output'];
  /** The items in the box, either packs or modules */
  items: Array<Item>;
  /** The license plate ID string of the box */
  licensePlate?: Maybe<Scalars['String']['output']>;
};

/** A Box type that is used to add Battery packs and modules to for a pickup order */
export type BoxType = {
  __typename?: 'BoxType';
  /** The name of the Box. Used as a display name for Boxs to the user. */
  displayName: Scalars['String']['output'];
  /** The unique identifier for the Box. Used to reference the Box in the system. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Weight of the Box */
  netWeight: Scalars['Int']['output'];
};

export type BoxTypeInput = {
  /** The name of the Box. Used as a display name for Boxs to the user. */
  displayName: Scalars['String']['input'];
  /** If the box is a standard box, the reference ID for this box for our internal use */
  internalID?: InputMaybe<Scalars['UUID']['input']>;
  /** Weight of the Box, if known */
  netWeight?: InputMaybe<Scalars['Int']['input']>;
};

/** Contact person for a pickup order */
export type Contact = {
  __typename?: 'Contact';
  /** The name of the person. */
  contactName: Scalars['String']['output'];
  /** The email address of the person. */
  email: Scalars['String']['output'];
  /** The unique identifier for the person. Used to reference the person in the system. */
  id: Scalars['UUID']['output'];
  /** The phone number of the person. */
  phone: Scalars['String']['output'];
};

export type DeleteBoxInput = {
  boxId: Scalars['UUID']['input'];
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

export type DeleteContactInput = {
  contactId: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

export type DeleteGroupInput = {
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  groupId: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

export type DeletePickupOrderInput = {
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

/** A file uploaded to the system */
export type File = {
  __typename?: 'File';
  /** The unique identifier for the file. Used to reference the file in the system. */
  id: Scalars['String']['output'];
  /** The url this file can be access through, this is a signed url that will expire after a certain amount of time. */
  url: Scalars['String']['output'];
};

/** An item in a box, either a pack or a module */
export type Item = Module | Pack;

/** A generic location in the Customer portal, used for pickup orders and other location based information. */
export type Location = {
  __typename?: 'Location';
  /** The name of the location. */
  displayName: Scalars['String']['output'];
  /** The unique identifier for the location. Used to reference the location in the system. */
  id: Scalars['UUID']['output'];
};

/** An actual module, essentially an instance of ModuleType */
export type Module = {
  __typename?: 'Module';
  /** The name of the Module. Used as a display name for Modules to the user. */
  displayName: Scalars['String']['output'];
  /** The id of the uploaded file */
  fileIDs: Array<Scalars['UUID']['output']>;
  /** The number of green modules */
  green: Scalars['Int']['output'];
  /** The unique identifier for the module. Used to reference the module in the system. */
  id: Scalars['UUID']['output'];
  /** The type of module */
  moduleType: ModuleType;
  /** The weight of the module */
  netWeight: Scalars['Int']['output'];
  /** The number of red modules */
  red: Scalars['Int']['output'];
  /** The number of yellow modules */
  yellow: Scalars['Int']['output'];
};

/** ModuleType describes the different types of modules that can be added to a pickup order. */
export type ModuleType = {
  __typename?: 'ModuleType';
  /** The default net weight of the module. */
  defaultNetWeight: Scalars['Int']['output'];
  /** Display name of the module. */
  displayName: Scalars['String']['output'];
  /** The unique identifier for the module. Used to reference the module in the system. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** The ID used in other systems to reference the module. (ERP) */
  referenceId: Scalars['String']['output'];
  /** The full internal product name of the module */
  referenceName: Scalars['String']['output'];
  /** The tenant identifier for the module. */
  tenant: Scalars['String']['output'];
};

export type ModuleTypeInput = {
  /** The name of the module. Used as a display name for module to the user. */
  displayName: Scalars['String']['input'];
  /** If the module is a standard module, the reference ID for this module for our internal use */
  internalID?: InputMaybe<Scalars['UUID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept the privacy policy */
  acceptPrivacyPolicy: User;
  /** Add a contact to a pickup order */
  addContact: PickupOrder;
  /** Add a location and date to a pickup order */
  addLocationAndDate?: Maybe<PickupOrder>;
  /** Add a module to a pickup order */
  addModule: PickupOrder;
  /** Add a pack to a pickup order */
  addPack: PickupOrder;
  /** Add a subscriber to the tracking label for a pickup order */
  addSubscriber: PickupOrder;
  /** Create a new pickup order */
  createPickupOrder?: Maybe<PickupOrder>;
  /** Delete a file, scoped on the current users tenant */
  delete: Scalars['Boolean']['output'];
  /** Delete a box from a pickup order */
  deleteBox: PickupOrder;
  /** Delete a contact from a pickup order */
  deleteContact: PickupOrder;
  /** Delete a group from a pickup order */
  deleteGroup: PickupOrder;
  /** Delete a pickup order */
  deletePickupOrder: Scalars['Boolean']['output'];
  /** Submit a pickup order, indicating that it is ready for pickup */
  submitPickupOrder: PickupOrder;
  /** Update a box in a pickup order */
  updateBox: PickupOrder;
  /** Upload a file, scoped on the current users tenant */
  upload: File;
};


export type MutationAcceptPrivacyPolicyArgs = {
  input: AcceptPrivacyPolicyInput;
};


export type MutationAddContactArgs = {
  input?: InputMaybe<AddContactInput>;
};


export type MutationAddLocationAndDateArgs = {
  input?: InputMaybe<AddLocationAndDateInput>;
};


export type MutationAddModuleArgs = {
  input?: InputMaybe<AddModuleInput>;
};


export type MutationAddPackArgs = {
  input?: InputMaybe<AddPackInput>;
};


export type MutationAddSubscriberArgs = {
  input?: InputMaybe<AddSubscriberInput>;
};


export type MutationDeleteArgs = {
  fileId: Scalars['ID']['input'];
};


export type MutationDeleteBoxArgs = {
  input?: InputMaybe<DeleteBoxInput>;
};


export type MutationDeleteContactArgs = {
  input?: InputMaybe<DeleteContactInput>;
};


export type MutationDeleteGroupArgs = {
  input?: InputMaybe<DeleteGroupInput>;
};


export type MutationDeletePickupOrderArgs = {
  input?: InputMaybe<DeletePickupOrderInput>;
};


export type MutationSubmitPickupOrderArgs = {
  input?: InputMaybe<SubmitPickupOrderInput>;
};


export type MutationUpdateBoxArgs = {
  input?: InputMaybe<UpdateBoxInput>;
};


export type MutationUploadArgs = {
  file: Scalars['Upload']['input'];
};

/** The status of a pickup order */
export enum OrderStatus {
  /** The order is approved and ready for pickup */
  Approved = 'Approved',
  /** The order is picked up */
  Arrived = 'Arrived',
  /** The order is being reviewed by revolt, and can't be edited */
  AwaitingApproval = 'AwaitingApproval',
  /** The order is being created */
  Draft = 'Draft',
  /** The is sent for review, but can still be edited */
  Pending = 'Pending',
  /** Something is likely wrong with the order */
  Unknown = 'Unknown'
}

/** An actual pack, essentially an instance of PackType */
export type Pack = {
  __typename?: 'Pack';
  /** The name of the Pack. Used as a display name for Packs to the user. */
  displayName: Scalars['String']['output'];
  /** The id of the uploaded file */
  fileIDs: Array<Scalars['UUID']['output']>;
  /** The number of green packs */
  green: Scalars['Int']['output'];
  /** The unique identifier for the pack. Used to reference the pack in the system. */
  id: Scalars['UUID']['output'];
  /** The weight of the pack */
  netWeight: Scalars['Int']['output'];
  /** The type of pack */
  packType: PackType;
  /** The number of red packs */
  red: Scalars['Int']['output'];
  /** The number of yellow packs */
  yellow: Scalars['Int']['output'];
};

/** PackType describes the different types of packs that can be added to a pickup order. */
export type PackType = {
  __typename?: 'PackType';
  /** The default net weight of the pack. */
  defaultNetWeight: Scalars['Int']['output'];
  /** Display name of the pack. */
  displayName: Scalars['String']['output'];
  /** The unique identifier for the pack. Used to reference the pack in the system. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** The ID used in other systems to reference the pack. (ERP) */
  referenceId: Scalars['String']['output'];
  /** The full internal product name of the pack */
  referenceName: Scalars['String']['output'];
  /** The tenant identifier for the pack. */
  tenant: Scalars['String']['output'];
};

export type PackTypeInput = {
  /** The name of the Pack. Used as a display name for Packs to the user. */
  displayName: Scalars['String']['input'];
  /** If the pack is a standard pack, the reference ID for this pack for our internal use */
  internalID?: InputMaybe<Scalars['UUID']['input']>;
};

/** PickupOrder contains user submitted information about batteries to pick up. */
export type PickupOrder = {
  __typename?: 'PickupOrder';
  /** The Boxs that are part of the pickup order. */
  boxes: Array<Box>;
  /** The contacts for the order */
  contacts: Array<Contact>;
  /** The User who created the order. */
  createdBy?: Maybe<User>;
  /** A unique tag for the current state of this order. Prevents accidental double updates */
  etag: Scalars['UUID']['output'];
  /** The unique identifier for the pickup order. Used to reference the order in the system. */
  id: Scalars['UUID']['output'];
  /** The Order ID string associated with the order. This is based on the order license plate. */
  orderID?: Maybe<Scalars['String']['output']>;
  /** The date when the batteries should be picked up, on the format YYYY-MM-DD. */
  pickupDate?: Maybe<Scalars['String']['output']>;
  /** Where should the batteries be picked up from? */
  pickupLocation?: Maybe<Location>;
  /** Purchase order from ERP */
  purchaseOrderId: Scalars['String']['output'];
  /** The current status of the order. */
  status: OrderStatus;
  /** The contacts for sharing tracking labels */
  subscribers: Array<Contact>;
  /** Tenant identifier for the pickup order. */
  tenant: Scalars['String']['output'];
  /** True/false depending on whether a tracking label is available in S3 for the order */
  trackingLabelAvailable: Scalars['Boolean']['output'];
  /** Last updated at */
  updatedAt: Scalars['String']['output'];
};

export enum PickupOrderFlow {
  /** Add package screen */
  AddPackage = 'AddPackage',
  /** Add package screen for small volume customers */
  AddPackageSmallVolume = 'AddPackageSmallVolume',
  /** Examplte Step to illustrate how it would work adding new steps */
  Example = 'Example',
  /** Should we show the intro screen for the pickup order flow */
  Intro = 'Intro',
  /** Introduction for small volume customers */
  IntroSmallVolume = 'IntroSmallVolume',
  /** Location and Date selector */
  LocationAndDate = 'LocationAndDate'
}

export type Query = {
  __typename?: 'Query';
  /** Get all box types for the current users tenant */
  boxTypes: Array<BoxType>;
  /** Get a file from it's ID, scoped on the current users tenant */
  file?: Maybe<File>;
  /** Get all locations for the current users tenant */
  locations: Array<Location>;
  /** The currently authenticated user */
  me: User;
  /** Get all modules for the current users tenant */
  moduleTypes: Array<ModuleType>;
  /** Get all pack types for the current users tenant */
  packTypes: Array<PackType>;
  /** Get a pickup order by ID, scoped by the current users tenant */
  pickupOrder: PickupOrder;
  /** Get all pickup orders for the current users tenant */
  pickupOrders: Array<PickupOrder>;
  /** Get the current tenants configuration */
  tenant?: Maybe<Tenant>;
  /** Get a tracking label for a specific order ID */
  trackingLabel: TrackingLabel;
  /** Get a user by ID (Also limited by the current tenant) */
  user: User;
  /** Get all users for the current tenant */
  users: Array<User>;
};


export type QueryFileArgs = {
  fileId: Scalars['ID']['input'];
};


export type QueryPickupOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTrackingLabelArgs = {
  orderID: Scalars['UUID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};

export type SubmitPickupOrderInput = {
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  pickupOrderId: Scalars['UUID']['input'];
};

/** A tenant in the customer portal */
export type Tenant = {
  __typename?: 'Tenant';
  /** The display name of the tenant */
  displayName: Scalars['String']['output'];
  /** The unique identifier for the tenant. Used to reference the tenant in the system. */
  id: Scalars['UUID']['output'];
  /** What pickup flow the specific tenant is using */
  pickupOrderFlow: Array<PickupOrderFlow>;
  /** The internal name of the tenant. This is used to reference the tenant in the system. */
  tenant: Scalars['String']['output'];
};

/** A tracking label for a specific pickup order */
export type TrackingLabel = {
  __typename?: 'TrackingLabel';
  /** The unique identifier for the pickup order associated with this tracking label. */
  orderID: Scalars['String']['output'];
  /** The url this tracking label can be access through. This is a signed url that will expire after a certain amount of time. */
  url: Scalars['String']['output'];
};

export type UpdateBoxInput = {
  boxId: Scalars['UUID']['input'];
  boxTypeId: Scalars['UUID']['input'];
  /** The etag of the pickup order, ensuring that the pickup order has not been updated since the last time it was fetched */
  etag: Scalars['UUID']['input'];
  modules: Array<UpdateModulePartInput>;
  packs: Array<UpdatePackPartInput>;
  pickupOrderId: Scalars['UUID']['input'];
};

export type UpdateModulePartInput = {
  green: Scalars['Int']['input'];
  moduleId: Scalars['UUID']['input'];
  moduleType: ModuleTypeInput;
  netWeight: Scalars['Int']['input'];
  red: Scalars['Int']['input'];
  yellow: Scalars['Int']['input'];
};

export type UpdatePackPartInput = {
  green: Scalars['Int']['input'];
  netWeight: Scalars['Int']['input'];
  packId: Scalars['UUID']['input'];
  packType: PackTypeInput;
  red: Scalars['Int']['input'];
  yellow: Scalars['Int']['input'];
};

/** A user in the customer portal */
export type User = {
  __typename?: 'User';
  /** The email address of the user. */
  email: Scalars['String']['output'];
  /** The first name of the user. */
  firstName: Scalars['String']['output'];
  /** The unique identifier for the user. Used to reference the user in the system. */
  id: Scalars['String']['output'];
  /** The last name of the user. */
  lastName: Scalars['String']['output'];
  /** The preferred language of the user. */
  preferredLanguage: Scalars['String']['output'];
  /**
   * The date and time the user accepted the privacy policy.
   * If blank, the user has not accepted the privacy policy.
   */
  privacyPolicyAccepted?: Maybe<Scalars['String']['output']>;
  /** The tenant identifier for the user. */
  tenant: Tenant;
};

export type AddPackMutationVariables = Exact<{
  input: AddPackInput;
}>;


export type AddPackMutation = { __typename?: 'Mutation', addPack: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type AddModuleMutationVariables = Exact<{
  input: AddModuleInput;
}>;


export type AddModuleMutation = { __typename?: 'Mutation', addModule: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type DeleteBoxMutationVariables = Exact<{
  input: DeleteBoxInput;
}>;


export type DeleteBoxMutation = { __typename?: 'Mutation', deleteBox: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type UpdateBoxMutationVariables = Exact<{
  input: UpdateBoxInput;
}>;


export type UpdateBoxMutation = { __typename?: 'Mutation', updateBox: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type SubmitOrderMutationVariables = Exact<{
  input: SubmitPickupOrderInput;
}>;


export type SubmitOrderMutation = { __typename?: 'Mutation', submitPickupOrder: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> } };

export type DeleteOrderMutationVariables = Exact<{
  input: DeletePickupOrderInput;
}>;


export type DeleteOrderMutation = { __typename?: 'Mutation', deletePickupOrder: boolean };

export type AddContactMutationVariables = Exact<{
  input: AddContactInput;
}>;


export type AddContactMutation = { __typename?: 'Mutation', addContact: { __typename?: 'PickupOrder', id: any, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }> } };

export type DeleteContactMutationVariables = Exact<{
  input: DeleteContactInput;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: { __typename?: 'PickupOrder', id: any, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }> } };

export type ContactFragment = { __typename?: 'Contact', id: any, contactName: string, phone: string };

export type AddSubscriberMutationVariables = Exact<{
  input: AddSubscriberInput;
}>;


export type AddSubscriberMutation = { __typename?: 'Mutation', addSubscriber: { __typename?: 'PickupOrder', id: any, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }> } };

export type DeleteSubscriberMutationVariables = Exact<{
  input: DeleteContactInput;
}>;


export type DeleteSubscriberMutation = { __typename?: 'Mutation', deleteContact: { __typename?: 'PickupOrder', id: any, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }> } };

export type SubscriberFragment = { __typename?: 'Contact', id: any, email: string };

export type PickupOrderLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type PickupOrderLocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: any, displayName: string }> };

export type AddLocationAndDateMutationVariables = Exact<{
  input: AddLocationAndDateInput;
}>;


export type AddLocationAndDateMutation = { __typename?: 'Mutation', addLocationAndDate?: { __typename?: 'PickupOrder', id: any, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null } | null };

export type LocationFragment = { __typename?: 'Location', id: any, displayName: string };

export type CreatePickupOrderMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePickupOrderMutation = { __typename?: 'Mutation', createPickupOrder?: { __typename?: 'PickupOrder', id: any } | null };

export type CreateOrderFragment = { __typename?: 'PickupOrder', id: any };

export type PickupOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type PickupOrdersQuery = { __typename?: 'Query', pickupOrders: Array<{ __typename?: 'PickupOrder', id: any, tenant: string, etag: any, status: OrderStatus, pickupDate?: string | null, orderID?: string | null, trackingLabelAvailable: boolean, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }>, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null }> };

export type TrackingLabelQueryVariables = Exact<{
  orderID: Scalars['UUID']['input'];
}>;


export type TrackingLabelQuery = { __typename?: 'Query', trackingLabel: { __typename?: 'TrackingLabel', url: string } };

export type OrderListFragment = { __typename?: 'PickupOrder', id: any, tenant: string, etag: any, status: OrderStatus, pickupDate?: string | null, orderID?: string | null, trackingLabelAvailable: boolean, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }>, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null };

export type OverviewUserFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, email: string };

export type OrderListBoxFragment = { __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> };

export type OrderListPackFragment = { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } };

export type OrderListModuleFragment = { __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } };

export type ExistingPickupOrderFlowQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ExistingPickupOrderFlowQuery = { __typename?: 'Query', pickupOrder: { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> }, boxTypes: Array<{ __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }>, packTypes: Array<{ __typename?: 'PackType', id?: any | null, displayName: string, defaultNetWeight: number }>, moduleTypes: Array<{ __typename?: 'ModuleType', id?: any | null, displayName: string, defaultNetWeight: number }>, tenant?: { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> } | null };

export type NewPickupOrderFlowQueryVariables = Exact<{ [key: string]: never; }>;


export type NewPickupOrderFlowQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> } | null };

export type OrderFragment = { __typename?: 'PickupOrder', id: any, tenant: string, status: OrderStatus, etag: any, pickupDate?: string | null, pickupLocation?: { __typename?: 'Location', id: any, displayName: string } | null, contacts: Array<{ __typename?: 'Contact', id: any, contactName: string, phone: string }>, subscribers: Array<{ __typename?: 'Contact', id: any, email: string }>, boxes: Array<{ __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> }> };

export type TenantFragment = { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> };

export type BoxFragment = { __typename?: 'Box', id: any, group: any, boxType: { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number }, items: Array<{ __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } } | { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } }> };

export type PackFragment = { __typename?: 'Pack', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, packType: { __typename?: 'PackType', id?: any | null, displayName: string } };

export type ModuleFragment = { __typename?: 'Module', id: any, displayName: string, netWeight: number, green: number, yellow: number, red: number, fileIDs: Array<any>, moduleType: { __typename?: 'ModuleType', id?: any | null, displayName: string } };

export type BoxTypeFragment = { __typename?: 'BoxType', id?: any | null, displayName: string, netWeight: number };

export type PackTypeFragment = { __typename?: 'PackType', id?: any | null, displayName: string, defaultNetWeight: number };

export type ModuleTypeFragment = { __typename?: 'ModuleType', id?: any | null, displayName: string, defaultNetWeight: number };

export type ProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilePageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, privacyPolicyAccepted?: string | null, tenant: { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> } } };

export type UserFragment = { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, privacyPolicyAccepted?: string | null, tenant: { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> } };

export type AcceptPrivacyPolicyMutationVariables = Exact<{
  input: AcceptPrivacyPolicyInput;
}>;


export type AcceptPrivacyPolicyMutation = { __typename?: 'Mutation', acceptPrivacyPolicy: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, privacyPolicyAccepted?: string | null, tenant: { __typename?: 'Tenant', id: any, displayName: string, pickupOrderFlow: Array<PickupOrderFlow> } } };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', upload: { __typename?: 'File', id: string, url: string } };

export type DeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', delete: boolean };

export type FileQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;


export type FileQuery = { __typename?: 'Query', file?: { __typename?: 'File', url: string } | null };

export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  displayName
}
    `;
export const CreateOrderFragmentDoc = gql`
    fragment CreateOrder on PickupOrder {
  id
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  id
  contactName
  phone
}
    `;
export const SubscriberFragmentDoc = gql`
    fragment Subscriber on Contact {
  id
  email
}
    `;
export const OrderListPackFragmentDoc = gql`
    fragment OrderListPack on Pack {
  id
  displayName
  packType {
    id
    displayName
  }
  netWeight
  green
  yellow
  red
  fileIDs
}
    `;
export const OrderListModuleFragmentDoc = gql`
    fragment OrderListModule on Module {
  id
  displayName
  moduleType {
    id
    displayName
  }
  netWeight
  green
  yellow
  red
  fileIDs
}
    `;
export const OrderListBoxFragmentDoc = gql`
    fragment OrderListBox on Box {
  id
  group
  boxType {
    id
    displayName
    netWeight
  }
  items {
    ... on Pack {
      ...OrderListPack
    }
    ... on Module {
      ...OrderListModule
    }
  }
}
    ${OrderListPackFragmentDoc}
${OrderListModuleFragmentDoc}`;
export const OverviewUserFragmentDoc = gql`
    fragment OverviewUser on User {
  id
  firstName
  lastName
  email
}
    `;
export const OrderListFragmentDoc = gql`
    fragment OrderList on PickupOrder {
  id
  tenant
  etag
  status
  pickupDate
  pickupLocation {
    id
    displayName
  }
  contacts {
    ...Contact
  }
  subscribers {
    ...Subscriber
  }
  boxes {
    ...OrderListBox
  }
  orderID
  createdBy {
    ...OverviewUser
  }
  trackingLabelAvailable
}
    ${ContactFragmentDoc}
${SubscriberFragmentDoc}
${OrderListBoxFragmentDoc}
${OverviewUserFragmentDoc}`;
export const PackFragmentDoc = gql`
    fragment Pack on Pack {
  id
  displayName
  packType {
    id
    displayName
  }
  netWeight
  green
  yellow
  red
  fileIDs
}
    `;
export const ModuleFragmentDoc = gql`
    fragment Module on Module {
  id
  displayName
  moduleType {
    id
    displayName
  }
  netWeight
  green
  yellow
  red
  fileIDs
}
    `;
export const BoxFragmentDoc = gql`
    fragment Box on Box {
  id
  group
  boxType {
    id
    displayName
    netWeight
  }
  items {
    ... on Pack {
      ...Pack
    }
    ... on Module {
      ...Module
    }
  }
}
    ${PackFragmentDoc}
${ModuleFragmentDoc}`;
export const OrderFragmentDoc = gql`
    fragment Order on PickupOrder {
  id
  tenant
  status
  etag
  pickupDate
  pickupLocation {
    id
    displayName
  }
  contacts {
    ...Contact
  }
  subscribers {
    ...Subscriber
  }
  boxes {
    ...Box
  }
}
    ${ContactFragmentDoc}
${SubscriberFragmentDoc}
${BoxFragmentDoc}`;
export const TenantFragmentDoc = gql`
    fragment Tenant on Tenant {
  id
  displayName
  pickupOrderFlow
}
    `;
export const BoxTypeFragmentDoc = gql`
    fragment BoxType on BoxType {
  id
  displayName
  netWeight
}
    `;
export const PackTypeFragmentDoc = gql`
    fragment PackType on PackType {
  id
  displayName
  defaultNetWeight
}
    `;
export const ModuleTypeFragmentDoc = gql`
    fragment ModuleType on ModuleType {
  id
  displayName
  defaultNetWeight
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  tenant {
    id
    displayName
    pickupOrderFlow
  }
  email
  firstName
  lastName
  privacyPolicyAccepted
}
    `;
export const AddPackDocument = gql`
    mutation AddPack($input: AddPackInput!) {
  addPack(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type AddPackMutationFn = Apollo.MutationFunction<AddPackMutation, AddPackMutationVariables>;

/**
 * __useAddPackMutation__
 *
 * To run a mutation, you first call `useAddPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPackMutation, { data, loading, error }] = useAddPackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPackMutation(baseOptions?: Apollo.MutationHookOptions<AddPackMutation, AddPackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPackMutation, AddPackMutationVariables>(AddPackDocument, options);
      }
export type AddPackMutationHookResult = ReturnType<typeof useAddPackMutation>;
export type AddPackMutationResult = Apollo.MutationResult<AddPackMutation>;
export type AddPackMutationOptions = Apollo.BaseMutationOptions<AddPackMutation, AddPackMutationVariables>;
export const AddModuleDocument = gql`
    mutation AddModule($input: AddModuleInput!) {
  addModule(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type AddModuleMutationFn = Apollo.MutationFunction<AddModuleMutation, AddModuleMutationVariables>;

/**
 * __useAddModuleMutation__
 *
 * To run a mutation, you first call `useAddModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addModuleMutation, { data, loading, error }] = useAddModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddModuleMutation(baseOptions?: Apollo.MutationHookOptions<AddModuleMutation, AddModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddModuleMutation, AddModuleMutationVariables>(AddModuleDocument, options);
      }
export type AddModuleMutationHookResult = ReturnType<typeof useAddModuleMutation>;
export type AddModuleMutationResult = Apollo.MutationResult<AddModuleMutation>;
export type AddModuleMutationOptions = Apollo.BaseMutationOptions<AddModuleMutation, AddModuleMutationVariables>;
export const DeleteBoxDocument = gql`
    mutation DeleteBox($input: DeleteBoxInput!) {
  deleteBox(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type DeleteBoxMutationFn = Apollo.MutationFunction<DeleteBoxMutation, DeleteBoxMutationVariables>;

/**
 * __useDeleteBoxMutation__
 *
 * To run a mutation, you first call `useDeleteBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoxMutation, { data, loading, error }] = useDeleteBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBoxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBoxMutation, DeleteBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBoxMutation, DeleteBoxMutationVariables>(DeleteBoxDocument, options);
      }
export type DeleteBoxMutationHookResult = ReturnType<typeof useDeleteBoxMutation>;
export type DeleteBoxMutationResult = Apollo.MutationResult<DeleteBoxMutation>;
export type DeleteBoxMutationOptions = Apollo.BaseMutationOptions<DeleteBoxMutation, DeleteBoxMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
  deleteGroup(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const UpdateBoxDocument = gql`
    mutation UpdateBox($input: UpdateBoxInput!) {
  updateBox(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type UpdateBoxMutationFn = Apollo.MutationFunction<UpdateBoxMutation, UpdateBoxMutationVariables>;

/**
 * __useUpdateBoxMutation__
 *
 * To run a mutation, you first call `useUpdateBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoxMutation, { data, loading, error }] = useUpdateBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBoxMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoxMutation, UpdateBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoxMutation, UpdateBoxMutationVariables>(UpdateBoxDocument, options);
      }
export type UpdateBoxMutationHookResult = ReturnType<typeof useUpdateBoxMutation>;
export type UpdateBoxMutationResult = Apollo.MutationResult<UpdateBoxMutation>;
export type UpdateBoxMutationOptions = Apollo.BaseMutationOptions<UpdateBoxMutation, UpdateBoxMutationVariables>;
export const SubmitOrderDocument = gql`
    mutation SubmitOrder($input: SubmitPickupOrderInput!) {
  submitPickupOrder(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type SubmitOrderMutationFn = Apollo.MutationFunction<SubmitOrderMutation, SubmitOrderMutationVariables>;

/**
 * __useSubmitOrderMutation__
 *
 * To run a mutation, you first call `useSubmitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrderMutation, { data, loading, error }] = useSubmitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOrderMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOrderMutation, SubmitOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOrderMutation, SubmitOrderMutationVariables>(SubmitOrderDocument, options);
      }
export type SubmitOrderMutationHookResult = ReturnType<typeof useSubmitOrderMutation>;
export type SubmitOrderMutationResult = Apollo.MutationResult<SubmitOrderMutation>;
export type SubmitOrderMutationOptions = Apollo.BaseMutationOptions<SubmitOrderMutation, SubmitOrderMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($input: DeletePickupOrderInput!) {
  deletePickupOrder(input: $input)
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const AddContactDocument = gql`
    mutation AddContact($input: AddContactInput!) {
  addContact(input: $input) {
    id
    contacts {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}`;
export type AddContactMutationFn = Apollo.MutationFunction<AddContactMutation, AddContactMutationVariables>;

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactMutation(baseOptions?: Apollo.MutationHookOptions<AddContactMutation, AddContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument, options);
      }
export type AddContactMutationHookResult = ReturnType<typeof useAddContactMutation>;
export type AddContactMutationResult = Apollo.MutationResult<AddContactMutation>;
export type AddContactMutationOptions = Apollo.BaseMutationOptions<AddContactMutation, AddContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    id
    contacts {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}`;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const AddSubscriberDocument = gql`
    mutation AddSubscriber($input: AddSubscriberInput!) {
  addSubscriber(input: $input) {
    id
    subscribers {
      ...Subscriber
    }
  }
}
    ${SubscriberFragmentDoc}`;
export type AddSubscriberMutationFn = Apollo.MutationFunction<AddSubscriberMutation, AddSubscriberMutationVariables>;

/**
 * __useAddSubscriberMutation__
 *
 * To run a mutation, you first call `useAddSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriberMutation, { data, loading, error }] = useAddSubscriberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<AddSubscriberMutation, AddSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubscriberMutation, AddSubscriberMutationVariables>(AddSubscriberDocument, options);
      }
export type AddSubscriberMutationHookResult = ReturnType<typeof useAddSubscriberMutation>;
export type AddSubscriberMutationResult = Apollo.MutationResult<AddSubscriberMutation>;
export type AddSubscriberMutationOptions = Apollo.BaseMutationOptions<AddSubscriberMutation, AddSubscriberMutationVariables>;
export const DeleteSubscriberDocument = gql`
    mutation DeleteSubscriber($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    id
    subscribers {
      ...Subscriber
    }
  }
}
    ${SubscriberFragmentDoc}`;
export type DeleteSubscriberMutationFn = Apollo.MutationFunction<DeleteSubscriberMutation, DeleteSubscriberMutationVariables>;

/**
 * __useDeleteSubscriberMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriberMutation, { data, loading, error }] = useDeleteSubscriberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriberMutation, DeleteSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriberMutation, DeleteSubscriberMutationVariables>(DeleteSubscriberDocument, options);
      }
export type DeleteSubscriberMutationHookResult = ReturnType<typeof useDeleteSubscriberMutation>;
export type DeleteSubscriberMutationResult = Apollo.MutationResult<DeleteSubscriberMutation>;
export type DeleteSubscriberMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriberMutation, DeleteSubscriberMutationVariables>;
export const PickupOrderLocationsDocument = gql`
    query PickupOrderLocations {
  locations {
    ...Location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __usePickupOrderLocationsQuery__
 *
 * To run a query within a React component, call `usePickupOrderLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupOrderLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupOrderLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupOrderLocationsQuery(baseOptions?: Apollo.QueryHookOptions<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>(PickupOrderLocationsDocument, options);
      }
export function usePickupOrderLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>(PickupOrderLocationsDocument, options);
        }
export function usePickupOrderLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>(PickupOrderLocationsDocument, options);
        }
export type PickupOrderLocationsQueryHookResult = ReturnType<typeof usePickupOrderLocationsQuery>;
export type PickupOrderLocationsLazyQueryHookResult = ReturnType<typeof usePickupOrderLocationsLazyQuery>;
export type PickupOrderLocationsSuspenseQueryHookResult = ReturnType<typeof usePickupOrderLocationsSuspenseQuery>;
export type PickupOrderLocationsQueryResult = Apollo.QueryResult<PickupOrderLocationsQuery, PickupOrderLocationsQueryVariables>;
export const AddLocationAndDateDocument = gql`
    mutation AddLocationAndDate($input: AddLocationAndDateInput!) {
  addLocationAndDate(input: $input) {
    id
    etag
    pickupDate
    pickupLocation {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type AddLocationAndDateMutationFn = Apollo.MutationFunction<AddLocationAndDateMutation, AddLocationAndDateMutationVariables>;

/**
 * __useAddLocationAndDateMutation__
 *
 * To run a mutation, you first call `useAddLocationAndDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationAndDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationAndDateMutation, { data, loading, error }] = useAddLocationAndDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLocationAndDateMutation(baseOptions?: Apollo.MutationHookOptions<AddLocationAndDateMutation, AddLocationAndDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLocationAndDateMutation, AddLocationAndDateMutationVariables>(AddLocationAndDateDocument, options);
      }
export type AddLocationAndDateMutationHookResult = ReturnType<typeof useAddLocationAndDateMutation>;
export type AddLocationAndDateMutationResult = Apollo.MutationResult<AddLocationAndDateMutation>;
export type AddLocationAndDateMutationOptions = Apollo.BaseMutationOptions<AddLocationAndDateMutation, AddLocationAndDateMutationVariables>;
export const CreatePickupOrderDocument = gql`
    mutation CreatePickupOrder {
  createPickupOrder {
    ...CreateOrder
  }
}
    ${CreateOrderFragmentDoc}`;
export type CreatePickupOrderMutationFn = Apollo.MutationFunction<CreatePickupOrderMutation, CreatePickupOrderMutationVariables>;

/**
 * __useCreatePickupOrderMutation__
 *
 * To run a mutation, you first call `useCreatePickupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupOrderMutation, { data, loading, error }] = useCreatePickupOrderMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePickupOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickupOrderMutation, CreatePickupOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickupOrderMutation, CreatePickupOrderMutationVariables>(CreatePickupOrderDocument, options);
      }
export type CreatePickupOrderMutationHookResult = ReturnType<typeof useCreatePickupOrderMutation>;
export type CreatePickupOrderMutationResult = Apollo.MutationResult<CreatePickupOrderMutation>;
export type CreatePickupOrderMutationOptions = Apollo.BaseMutationOptions<CreatePickupOrderMutation, CreatePickupOrderMutationVariables>;
export const PickupOrdersDocument = gql`
    query PickupOrders {
  pickupOrders {
    ...OrderList
  }
}
    ${OrderListFragmentDoc}`;

/**
 * __usePickupOrdersQuery__
 *
 * To run a query within a React component, call `usePickupOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupOrdersQuery(baseOptions?: Apollo.QueryHookOptions<PickupOrdersQuery, PickupOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupOrdersQuery, PickupOrdersQueryVariables>(PickupOrdersDocument, options);
      }
export function usePickupOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupOrdersQuery, PickupOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupOrdersQuery, PickupOrdersQueryVariables>(PickupOrdersDocument, options);
        }
export function usePickupOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PickupOrdersQuery, PickupOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PickupOrdersQuery, PickupOrdersQueryVariables>(PickupOrdersDocument, options);
        }
export type PickupOrdersQueryHookResult = ReturnType<typeof usePickupOrdersQuery>;
export type PickupOrdersLazyQueryHookResult = ReturnType<typeof usePickupOrdersLazyQuery>;
export type PickupOrdersSuspenseQueryHookResult = ReturnType<typeof usePickupOrdersSuspenseQuery>;
export type PickupOrdersQueryResult = Apollo.QueryResult<PickupOrdersQuery, PickupOrdersQueryVariables>;
export const TrackingLabelDocument = gql`
    query TrackingLabel($orderID: UUID!) {
  trackingLabel(orderID: $orderID) {
    url
  }
}
    `;

/**
 * __useTrackingLabelQuery__
 *
 * To run a query within a React component, call `useTrackingLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingLabelQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useTrackingLabelQuery(baseOptions: Apollo.QueryHookOptions<TrackingLabelQuery, TrackingLabelQueryVariables> & ({ variables: TrackingLabelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackingLabelQuery, TrackingLabelQueryVariables>(TrackingLabelDocument, options);
      }
export function useTrackingLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackingLabelQuery, TrackingLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackingLabelQuery, TrackingLabelQueryVariables>(TrackingLabelDocument, options);
        }
export function useTrackingLabelSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrackingLabelQuery, TrackingLabelQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrackingLabelQuery, TrackingLabelQueryVariables>(TrackingLabelDocument, options);
        }
export type TrackingLabelQueryHookResult = ReturnType<typeof useTrackingLabelQuery>;
export type TrackingLabelLazyQueryHookResult = ReturnType<typeof useTrackingLabelLazyQuery>;
export type TrackingLabelSuspenseQueryHookResult = ReturnType<typeof useTrackingLabelSuspenseQuery>;
export type TrackingLabelQueryResult = Apollo.QueryResult<TrackingLabelQuery, TrackingLabelQueryVariables>;
export const ExistingPickupOrderFlowDocument = gql`
    query ExistingPickupOrderFlow($id: UUID!) {
  pickupOrder(id: $id) {
    ...Order
  }
  boxTypes {
    ...BoxType
  }
  packTypes {
    ...PackType
  }
  moduleTypes {
    ...ModuleType
  }
  tenant {
    ...Tenant
  }
}
    ${OrderFragmentDoc}
${BoxTypeFragmentDoc}
${PackTypeFragmentDoc}
${ModuleTypeFragmentDoc}
${TenantFragmentDoc}`;

/**
 * __useExistingPickupOrderFlowQuery__
 *
 * To run a query within a React component, call `useExistingPickupOrderFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingPickupOrderFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingPickupOrderFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExistingPickupOrderFlowQuery(baseOptions: Apollo.QueryHookOptions<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables> & ({ variables: ExistingPickupOrderFlowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>(ExistingPickupOrderFlowDocument, options);
      }
export function useExistingPickupOrderFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>(ExistingPickupOrderFlowDocument, options);
        }
export function useExistingPickupOrderFlowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>(ExistingPickupOrderFlowDocument, options);
        }
export type ExistingPickupOrderFlowQueryHookResult = ReturnType<typeof useExistingPickupOrderFlowQuery>;
export type ExistingPickupOrderFlowLazyQueryHookResult = ReturnType<typeof useExistingPickupOrderFlowLazyQuery>;
export type ExistingPickupOrderFlowSuspenseQueryHookResult = ReturnType<typeof useExistingPickupOrderFlowSuspenseQuery>;
export type ExistingPickupOrderFlowQueryResult = Apollo.QueryResult<ExistingPickupOrderFlowQuery, ExistingPickupOrderFlowQueryVariables>;
export const NewPickupOrderFlowDocument = gql`
    query NewPickupOrderFlow {
  tenant {
    ...Tenant
  }
}
    ${TenantFragmentDoc}`;

/**
 * __useNewPickupOrderFlowQuery__
 *
 * To run a query within a React component, call `useNewPickupOrderFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewPickupOrderFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPickupOrderFlowQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewPickupOrderFlowQuery(baseOptions?: Apollo.QueryHookOptions<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>(NewPickupOrderFlowDocument, options);
      }
export function useNewPickupOrderFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>(NewPickupOrderFlowDocument, options);
        }
export function useNewPickupOrderFlowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>(NewPickupOrderFlowDocument, options);
        }
export type NewPickupOrderFlowQueryHookResult = ReturnType<typeof useNewPickupOrderFlowQuery>;
export type NewPickupOrderFlowLazyQueryHookResult = ReturnType<typeof useNewPickupOrderFlowLazyQuery>;
export type NewPickupOrderFlowSuspenseQueryHookResult = ReturnType<typeof useNewPickupOrderFlowSuspenseQuery>;
export type NewPickupOrderFlowQueryResult = Apollo.QueryResult<NewPickupOrderFlowQuery, NewPickupOrderFlowQueryVariables>;
export const ProfilePageDocument = gql`
    query ProfilePage {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export function useProfilePageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageSuspenseQueryHookResult = ReturnType<typeof useProfilePageSuspenseQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const AcceptPrivacyPolicyDocument = gql`
    mutation AcceptPrivacyPolicy($input: AcceptPrivacyPolicyInput!) {
  acceptPrivacyPolicy(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type AcceptPrivacyPolicyMutationFn = Apollo.MutationFunction<AcceptPrivacyPolicyMutation, AcceptPrivacyPolicyMutationVariables>;

/**
 * __useAcceptPrivacyPolicyMutation__
 *
 * To run a mutation, you first call `useAcceptPrivacyPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPrivacyPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPrivacyPolicyMutation, { data, loading, error }] = useAcceptPrivacyPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPrivacyPolicyMutation(baseOptions?: Apollo.MutationHookOptions<AcceptPrivacyPolicyMutation, AcceptPrivacyPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptPrivacyPolicyMutation, AcceptPrivacyPolicyMutationVariables>(AcceptPrivacyPolicyDocument, options);
      }
export type AcceptPrivacyPolicyMutationHookResult = ReturnType<typeof useAcceptPrivacyPolicyMutation>;
export type AcceptPrivacyPolicyMutationResult = Apollo.MutationResult<AcceptPrivacyPolicyMutation>;
export type AcceptPrivacyPolicyMutationOptions = Apollo.BaseMutationOptions<AcceptPrivacyPolicyMutation, AcceptPrivacyPolicyMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  upload(file: $file) {
    id
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($fileId: ID!) {
  delete(fileId: $fileId)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const FileDocument = gql`
    query File($fileId: ID!) {
  file(fileId: $fileId) {
    url
  }
}
    `;

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileQuery(baseOptions: Apollo.QueryHookOptions<FileQuery, FileQueryVariables> & ({ variables: FileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileQuery, FileQueryVariables>(FileDocument, options);
      }
export function useFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileQuery, FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileQuery, FileQueryVariables>(FileDocument, options);
        }
export function useFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileQuery, FileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileQuery, FileQueryVariables>(FileDocument, options);
        }
export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileSuspenseQueryHookResult = ReturnType<typeof useFileSuspenseQuery>;
export type FileQueryResult = Apollo.QueryResult<FileQuery, FileQueryVariables>;